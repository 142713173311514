<div class="sec-title sec-title-2">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <h2 class="title">Réinitialiser mon mot de passe</h2>
            </div>
        </div>
        <ng-container *ngIf="!emailSent">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <p>Veuillez saisir votre adresse email.</p>
                    <p>Vous recevrez un e-mail avec le lien de réinitialisation de votre mot de passe.</p>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="quote-item">
                        <input matInput placeholder="E-mail" [formControl]="email" required>
                        <span *ngIf="email.invalid && email.touched" class="error-message">{{getEmailErrorMessage()}}</span>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="quote-item">
                        <a class="btn-1" (click)="sendResetEmail()">Réinitialiser le mot de passe</a>
                        <span *ngIf="errorMessage" class="error-message"> {{ errorMessage}} </span>
                    </div>
                </div>
            </div>  
        </ng-container>
        <ng-container *ngIf="emailSent">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <p>Un email vous a été envoyé à l'adresse <span class="email-used">{{ email.value }}</span></p>
                    <p>Veuillez suivre les instructions dans l'email puis <a class="password-reset-link" (click)="goToLogin($event)">reconnectez vous ici</a></p>
                </div>
            </div>
        </ng-container>
    </div>    
</div>