import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { SaleComponent } from './sale/sale.component';
import { ContactComponent } from './contact/contact.component';
import { HeaderComponent } from './components/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccountComponent } from './account/account.component';
import { SalesComponent } from './sales/sales.component';
import { ResultsComponent } from './results/results.component';
import { FaqComponent } from './faq/faq.component';
import { LoginComponent } from './components/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';


import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';

import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from "ng-recaptcha";
import { HttpClientModule } from '@angular/common/http';

import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { RegisterComponent } from './components/register/register.component';
import { FooterComponent } from './components/footer/footer.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MatDialogModule } from '@angular/material/dialog';

import { AdminGuard } from './guards/admin.guard';
import { CguComponent } from './cgu/cgu.component';
import { TermsComponent } from './terms/terms.component';
import { ItemComponent } from './item/item.component';
import { CookiesComponent } from './components/cookies/cookies.component';

import { CookieService } from 'ngx-cookie-service';
import { WhoComponent } from './who/who.component';
import { ResultComponent } from './result/result.component';
import { ResultItemComponent } from './result-item/result-item.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SaleComponent,
    ContactComponent,
    HeaderComponent,
    LoginComponent,
    AccountComponent,
    SalesComponent,
    ResultsComponent,
    FaqComponent,
    RegisterComponent,
    FooterComponent,
    ResetPasswordComponent,
    CguComponent,
    TermsComponent,
    ItemComponent,
    CookiesComponent,
    WhoComponent,
    ResultComponent,
    ResultItemComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    NgbModule,
    FontAwesomeModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatChipsModule,
    MatExpansionModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule,
    MatDialogModule,
    GalleryModule,
    LightboxModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    AdminGuard,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LdO4-IbAAAAAKKT0L7xyatsbMATzVOemWEPlyFR'
      } as RecaptchaSettings
    },
    CookieService,
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
