import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { SalesService } from '../services/sales.service';
import * as models from '../services/models';
import { Subscription } from 'rxjs';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FormControl, Validators } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/admin/faqs/faqs.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  constructor(
    private userService: UserService,
    private salesService: SalesService,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  public logged: boolean = false;
  public user: models.User;
  public loadingFavSales: boolean;

  public faStar = farStar;
  public sales: models.Sale[];

  public favSales: models.Sale[] = [];

  public faCalendarAlt = faCalendarAlt;

  private subscriptions: Subscription[] = [];

  public reloggedBeforePasswordUpdate: boolean = false;
  public updatingPassword: boolean = false;
  public passwordUpdated: boolean = false;

  public hide = true;
  public password = new FormControl('', [Validators.required]);
  public password2 = new FormControl('', [Validators.required]);
  public errorMessage: string;

  // public newsletter: FormControl = new FormControl('');
  // public newsletterLoaded: boolean = false;

  public allowAlerts: FormControl = new FormControl('');
  public allowAlertsLoaded: boolean = false;

  @ViewChild('search') public search;

  ngOnInit(): void {
    window.scroll(0, 0);
    this.subscriptions.push(
      this.userService.getUser$().subscribe((user: models.User) => {
        if (user) {
          this.user = user;
          this.logged = true;
          this.loadingFavSales = true;

          /**
           * Load user's favorite sales
           */
          this.subscriptions.push(
            this.salesService.getSales$().subscribe((sales) => {
              this.sales = sales.sort((a, b) => {
                if (a.date < b.date)
                  return -1;
                if (a.date > b.date)
                  return 1;
                return 0;
              });

              const now = new Date();

              this.favSales = this.sales.filter(sale => {
                const saleDate = new Date(sale.date);
                return this.user.fav_sales && this.user.fav_sales.includes(sale.id)
              });

              this.favSales.map(sale => {
                let fileName: string;
                if (sale.default_picture) {
                  fileName = sale.default_picture;
                } else {
                  if (typeof sale.items[0]?.pictures === 'string' && sale.items[0]?.pictures) {
                    fileName = sale.items[0]?.pictures;
                  } else if (typeof sale.items[0]?.pictures === 'object' && sale.items[0]?.pictures) {
                    fileName = sale.items[0]?.pictures[0];
                  }
                }
                this.salesService.getItemPicture$(sale.id, fileName).subscribe((url) => {
                  this.favSales = this.favSales.map(newSale => {
                    if (newSale.id === sale.id) {
                      newSale.picture = url;
                    }
                    return newSale
                  })
                })
              })
              this.loadingFavSales = false;
            }),
          );
          this.allowAlerts.setValue(this.user.allow_alerts || false);
          this.allowAlertsLoaded = true;

          /* setTimeout(() => {
            this.subscriptions.push(
              this.userService.getUserNewsletterChoice(this.user.email).subscribe(choice => {
                this.newsletter.setValue(choice);
                this.newsletterLoaded = true;
              }, () => {
                this.newsletter.setValue(false);
                this.newsletterLoaded = true;
              }),
            );
          }, 2000); */

        } else {
          this.user = null;
          this.logged = false;
        }
      }),
    );
  }

  public addAlert(alert): void {
    if ((!this.user.alerts || this.user.alerts.length < 10) && alert.length > 1) {
      this.userService.addAlert(this.user, alert).toPromise();
      this.search.nativeElement.value = "";
    }
  }

  public deleteAlert(alert): void {
    this.userService.deleteAlert(this.user, alert).toPromise();
  }

  public logout() {
    this.userService.signOutUser();
  }

  public updatePassword() {
    this.password.markAsTouched();
    this.password2.markAsTouched();

    if (this.password.valid && this.password2.valid) {
      const password = this.password.value;

      this.userService.updatePassword(this.password.value)
        .pipe(catchError(error => {
          if (error.message) {
            this.errorMessage = error.message;
          }
          return error
        }))
        .subscribe(() => {
          this.passwordUpdated = true;
        });
    }
  }

  public getPasswordErrorMessage() {
    if (this.password.hasError('required')) {
      return 'Veuillez un nouveau mot de passe';
    }
  }

  public getPassword2ErrorMessage() {
    if (this.password2.hasError('required')) {
      return 'Veuillez confirmer votre nouveau mot de passe';
    }
  }

  public relogged() {
    this.reloggedBeforePasswordUpdate = true;
  }

  public goToAdmin() {
    if (this.user.admin) {
      this.router.navigate(['/admin']);
    }
  }

  public confirmAccountDeletion(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '30vw'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.deleteUser();
      }
    });
  }

  /* public changeNewsletterChoice(): void {
    this.userService.getUserNewsletterChoice(this.user.email).subscribe(() => {
      this.userService.updateUserNewsletterChoice(this.user.email, this.newsletter.value);
    }, (error) => {
      if (error && error.error && error.error.code === "document_not_found") {
        this.userService.createUserNewsletterChoice(this.user.email, this.newsletter.value);
      }
    })
  } */

  public changeAlertsChoice(): void {
    this.userService.updateUserAlertsChoice(this.user.id, this.allowAlerts.value);
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  template: '<h1 mat-dialog-title>Êtes-vous sûr de vouloir supprimer votre compte ?</h1> \
      <div mat-dialog-content> \
      <p>Ceci entraînera la suppression de toutes les données liées à votre compte et vous déconnectera du site.</p> \
      <div class="container"> \
        <div mat-dialog-actions class="row"> \
          <button class="form-control col-md-5" mat-button (click)="onNoClick()">Annuler</button> \
          <button class="form-control col-md-5 offset-md-1" mat-button (click)="onYesClick()">Confirmer</button> \
        </div> \
      </div> \
    </div>',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

}
