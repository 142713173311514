import { Component, OnInit } from '@angular/core';
import { FaqsService } from '../services/faqs.service';
import * as models from '../services/models';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(
    private faqsService: FaqsService,
  ) { }

  public faqs: models.Faq[] = [];

  ngOnInit(): void {
    window.scroll(0, 0);
    this.faqsService.getFaqs$().subscribe(faqs => {
      this.faqs = faqs.map(faq => ({
        ...faq,
        answer: this.stringToHTML(faq.answer),
      }));
    })
  }

  private stringToHTML(str) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return doc.body.innerHTML;
  };

}
