<!-- LOGIN / REGISTER -->
<div class="container" *ngIf="!logged && !updatingPassword"> 
    <div class="row">
        <div class="col-lg-6">
            <div class="sec-title sec-title-2">
                <h2>Créer un compte vous permettra de :</h2>
                <ul class="core-about">
                    <li><i class="fas fa-check check-icon"></i>
                        <h4>Vous faire connaître de nos services</h4>
                    </li>
                    <li><i class="fas fa-check check-icon"></i>
                        <h4>Rester informé de nos actualités grâce à notre Newsletter</h4>
                    </li>
                    <li><i class="fas fa-check check-icon"></i>
                        <h4>Nous contacter plus facilement</h4>
                    </li>
                    <li><i class="fas fa-check check-icon"></i>
                        <h4>Recevoir des alertes concernant les ventes qui vous intéressent</h4>
                    </li>
                    <li><i class="fas fa-check check-icon"></i>
                        <h4>Et plus encore...</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-lg-6">  
            <app-login></app-login>
        </div>
    </div>
    <div class="row register-container">
        <div class="col-lg-12">
            <app-register></app-register>
        </div>
    </div>
</div>

<!-- ACCOUNT -->
<div class="container" *ngIf="logged && !updatingPassword">
    <div class="row">
        <div class="col-lg-12">
            <div class="sec-title sec-title-2">
                <h2>Mes informations</h2>
            </div>
        </div>
        <div class='col-lg-1 p-0 avatar-container'>
            <mat-icon 
                class="avatar-icon">
                account_circle
            </mat-icon>
        </div>
        <div class='col-lg-6'>
            <p class="username">
                {{ user.lastname }} {{ user.firstname }}
            </p>
            <p class="email">
                {{ user.email }}
            </p>
            <p class="account-status" *ngIf="!user.validated">
                Compte non vérifié 
                <mat-icon 
                    matTooltip="Faites vous connaître de nos équipe et accédez à plus de fonctionnalités" 
                    matTooltipPosition="right"
                    class="unverified-icon">
                    help_outline
                </mat-icon>
            </p>
            <p class="account-status" *ngIf="user.validated">
                Compte vérifié 
                <mat-icon 
                    matTooltip="Vous êtes connu de nos services et pouvez accéder à plus de fonctionnalités." 
                    matTooltipPosition="right"
                    class="verified-icon">
                    verified_user
                </mat-icon>
            </p>
            <!-- <div class="row p-0 newsletter-row">
                <input class="col-1 p-0" type="checkbox" id="newsletter" name="newsletter" [formControl]="newsletter" (change)="changeNewsletterChoice()" class="newsletter-input" *ngIf="newsletterLoaded">
                <div class="col-1 p-0" *ngIf="!newsletterLoaded" class="circle-progress-spinner newsletter-input"></div>
                <label class="col-9 p-0" for="newsletter">
                    &nbsp;&nbsp; Je souhaite recevoir la newsletter
                    <mat-icon 
                        matTooltip="En cliquant ici, vous acceptez de recevoir la newsletter." 
                        matTooltipPosition="right"
                        class="mail-icon">
                        email
                    </mat-icon>
                </label>
            </div> -->
        </div>
        <div class='col-lg-3'>
            <button mat-stroked-button (click)="logout()" class="options-buttons"> 
                <mat-icon>logout</mat-icon> Me déconnecter
            </button>
            <button mat-stroked-button (click)="updatingPassword = true" class="options-buttons"> 
                <mat-icon>lock</mat-icon> Modifier mon mot de passe
            </button>
            <button mat-stroked-button *ngIf="user.admin" (click)="goToAdmin()" class="options-buttons"> 
                <mat-icon>admin_panel_settings</mat-icon> Espace Admininistation
            </button>
            <button mat-stroked-button (click)="confirmAccountDeletion()" class="options-buttons"> 
                <mat-icon>delete_forever</mat-icon> Supprimer mon compte
            </button>
        </div>
    </div>
</div>

<div class="container bottom-container" *ngIf="logged && !updatingPassword">
    <div class="row p-0">
        <!-- FAV SALES -->
        <div class='col-lg-7'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="sec-title sec-title-2">
                            <h2>Ventes favorites à venir</h2>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template [ngIf]="!loadingFavSales" [ngIfElse]="progressspinner">
                <ng-template [ngIf]="favSales && favSales.length > 0" [ngIfElse]="nofavsale">
                    <section class="about py-4" *ngFor="let sale of favSales">
                        <div class="container p-0">
                            <div class="row sale-row py-3">
                                <div class="col-lg-6">
                                    <div class="img-box">
                                        <div class="sale-img" [style]="{'background-image': 'url(' + sale.picture + ')'}">
                                        </div>
                                        <div class="case-about">
                                            <div class="counter">{{ sale.items.length }}</div>
                                            <h5>Lots</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="text-box">
                                        <div class="sec-title">
                                            <fa-icon [icon]="faCalendarAlt" class="calendar-icon"></fa-icon> <h2>{{ sale.date | date:'dd MMMM yyyy' }} à {{ sale.date | date:'shortTime' }}</h2>
                                            <h3 class="sale-title">{{ sale.title }}</h3>
                                            <h5 class="sale-adress"><i class="flaticon-location sale-location-icon"></i> {{ sale.site }}</h5>
                                            <p>{{ sale.description }}</p>
                                        </div>
                                        <div class="row see-more-container">
                                            <div class="col-sm-10">
                                                <a class="btn-1" [routerLink]="['/sale', sale.id]">Plus d'info sur la vente</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </ng-template>
                <ng-template #nofavsale>
                    <div class="container no-sale-container">
                        <div class="row">
                            <div class="col-lg-6">
                                <span>Vous n'avez aucune vente favorite à venir.</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
            <ng-template #progressspinner>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 spinner-container">
                            <div class="lil-progress-spinner"></div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        
        <!-- ALERTS -->
        <div class='col-lg-5'>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="sec-title sec-title-4">
                            <h2>Mes alertes</h2>
                            <div class="col-md-12 p-0">
                                <input class="col-1 p-0 allow-alerts-checkbox" type="checkbox" id="allowalerts" name="allowalerts" [formControl]="allowAlerts" (change)="changeAlertsChoice()">
                                <label class="col-11 p-0" for="allowalerts">J'accepte de recevoir les alertes e-mails de nouvelles ventes correspondant à mes mots-clefs.</label>
                            </div>
                            <h3>Ajoutez des mots-clefs</h3>
                            <h4>Vous recevrez une alerte par e-mail lorsqu'une vente correspondant à un de vos mots-clefs sera en ligne</h4>
                            <h4>Vous pouvez ajouter jusqu'à <u>10 alertes</u>.</h4>
                        </div>
                        <div class="col-lg-12 py-3 px-0">
                            <div class="widget">
                                <div class="widget-body">
                                    <div class="search">
                                        <input 
                                            #search type="search" 
                                            name="search" 
                                            placeholder="Ajouter un mot-clef (Exemple: Renault, Kangoo, Camionette...)"
                                            (keydown.enter)="addAlert($event.target.value)"
                                            [disabled]="(user && user.alerts && user.alerts.length >= 10) || (!allowAlerts.value && allowAlertsLoaded) || !allowAlertsLoaded"
                                        >
                                        <button class="click">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </div>
                                    <div class="chips-list" *ngIf="user && user.alerts">
                                        <mat-chip-list #chipList aria-label="Mes alertes">
                                            <mat-chip *ngFor="let alert of user.alerts" (removed)="deleteAlert(alert)">
                                                {{alert}}
                                                <mat-icon matChipRemove>cancel</mat-icon>
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container relog-container" *ngIf="logged && updatingPassword && !reloggedBeforePasswordUpdate">
    <div class="row p-0 justify-content-center">
        <div class="col-lg-6">
            <div class="sec-title sec-title-2">
                <h2>Reconnectez-vous</h2>
            </div>
            <span>Pour des raisons de sécurité, veuillez vous reconnecter avant de procéder à la modification du mot de passe.</span>
        </div>
        <div class="col-lg-9">
            <app-login [relog]="true" [forcedEmail]="user.email" (relogged)="relogged()"></app-login>
        </div>
    </div>
    <div class="row p-0 justify-content-end">
        <div class="col-lg-2">
            <button mat-stroked-button (click)="updatingPassword = false" class="options-buttons"> 
                <mat-icon>arrow_back</mat-icon> Annuler
            </button>
        </div>
        <div class="col-lg-2">
        </div>
    </div>
</div>


<div class="container password-update-container" *ngIf="logged && updatingPassword && reloggedBeforePasswordUpdate">
    <div class="row p-0 justify-content-center">
        <div class="col-lg-6">
            <div class="sec-title sec-title-2">
                <h2>Modification du mot de passe</h2>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!passwordUpdated">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="quote-item">
                    <input type="password" placeholder="Nouveau mot de passe" [formControl]="password" required>
                    <span *ngIf="password.invalid && password.touched" class="error-message">{{ getPasswordErrorMessage() }}</span>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="quote-item">
                    <input type="password" placeholder="Confirmer le nouveau mot de passe" [formControl]="password2" required>
                    <span *ngIf="password2.invalid && password2.touched" class="error-message">{{ getPassword2ErrorMessage() }}</span>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="quote-item">
                    <a class="btn-1" (click)="updatePassword()">Confirmer la modification</a>
                    <span *ngIf="errorMessage" class="error-message"> {{ errorMessage}} </span>
                </div>
            </div>
        </div>
        <div class="row p-0 justify-content-end">
            <div class="col-lg-2">
                <button mat-stroked-button (click)="updatingPassword = false" class="options-buttons"> 
                    <mat-icon>arrow_back</mat-icon> Annuler
                </button>
            </div>
            <div class="col-lg-2">
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="passwordUpdated">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <p>Votre mot de passe a été modifié. <a class="password-reset-link" (click)="updatingPassword = false">Revenir à mon espace utilisateur</a></p>
            </div>
        </div>
    </ng-container>
</div>