<div class="container">
    <div class="row">
        <div class="col-lg-6">
            <div class="sec-title sec-title-2">
                <h2>Conditions Générales d'Utilisation</h2>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-12 cgu-content">
            <h3>
                ARTICLE 1 – MENTIONS LEGALES
            </h3>
            <p>
                Les présentes conditions générales d’utilisation (ci-après « CGU ») sont établies par la SARL ARDECHE ENCHERES (ci-après «l’éditeur »), propriétaire et éditeur du site ardeche-encheres.com (ci-après nommé « le site »). La SARL ARDECHE ENCHERES est un opérateur de ventes volontaires (déclaration au conseil des ventes volontaires numéro 001/2011) au capital social de 10.000€, immatriculée au registre du commerce et des sociétés d’Aubenas sous le numéro 532 812 799, Siret n° 532 812 79900015. Son siège social est situé: 
            </p>
            <p>
                Quartier Rochebrune <br/>
                Route du Puy<br/>
                07100 Annonay
            </p>
            <p>
                Cette dernière est joignable à l’adresse suivante :
            </p>
            <p>
                SARL ARDECHE ENCHERES <br/>
                Quartier Rochebrune <br/>
                Route du Puy <br/>
                07100 Annonay <br/>
                Téléphone: 04.75.33.68.52 <br/>
                <a href="mailto:gregory.helbourg@sfr.fr">gregory.helbourg@sfr.fr</a>
            </p>
            <p>
                Le Site est hébergé par Google Firebase, domiciliée au 188 King ST, San Francisco, CA 94107, United States. 
                <a href="https://firebase.google.com/" target="_blank">firebase.google.com</a>.
            </p>
            <p>
                Les présentes conditions générales d’utilisation ont pour objet de définir les modalités et conditions dans lesquelles l’éditeur met à disposition son site et les services disponibles au bénéfice de l’utilisateur. En utilisant le site, l’utilisateur accepte sans réserve les présentes dispositions et conditions générales d’utilisation. L’acceptation électronique de ces conditions par l’utilisateur a la même valeur juridique qu’une signature de sa part. Cette acceptation suppose que l’utilisateur jouit de la pleine capacité juridique, ou de l’autorisation d’un tuteur, curateur ou représentant légal, ou encore de la détention d’un mandat s’il agit en représentation d’un tiers.
            </p>
            <p>
                En cas de non-acceptation des Conditions Générales d'Utilisation, l'utilisateur se doit de renoncer à l'accès au Site et à l’utilisation des Services.
            </p>
            <p>
                Les présentes conditions générales d’utilisation peuvent à tout moment, et sans préavis, faire l’objet d’une modification par l’éditeur afin de les mettre en conformité avec le droit applicable ou de modifier le contenu du site. Le cas échéant, les éditeurs doivent en informer l’utilisateur. Ce dernier, s’il ne conteste pas les modifications, sera réputé les avoir acceptées une (1) semaine après cette notification.
            </p>
            <p>
                Les Conditions Générales d’Utilisation sont en vigueur à compter de la mise en ligne du site et sont applicables à toute utilisation du Site.
            </p>

            <h3>
                ARTICLE 2 – LEXIQUE
            </h3>
            <p>
                <b>Calendrier des ventes</b> : Liste des prochaines ventes accompagnée de détails, d’explications. Il se trouve dans la page « ventes ».
            </p>
            <p>
                <b>Catalogue de vente</b> : Liste des lots mise en vente à une date précise, accompagnée des détails, photos, conditions, etc. L’utilisateur peut le consulter 
                librement en cliquant sur le bouton « Ventes ».
            </p>
            <p>
                <b>Conditions de vente</b> : Document descriptif regroupant toutes les informations et stipulations relatives à la vente d'un produit ou d'un service. Elles sont établies et peuvent varier pour chaque vente. L’utilisateur peut les consulter librement en cliquant sur le lien figurant sur la vente ou le site.
            </p>
            <p>
                <b>Commissaire-priseur</b> : Officier ministériel chargé de l'estimation des objets mobiliers et de leur vente aux enchères. Le commissaire-priseur de la SARL ARDECHE ENCHERES est Maître Grégory HELBOURG.
            </p>
            <p>
                <b>Cookies</b> : Fichier enregistré sur le disque dur de l'ordinateur de l’utilisateur à la demande du serveur gérant le site Web visité. Il contient des informations sur la navigation effectuée sur les pages de ce site, sans permettre l’identification de l’utilisateur.
            </p>
            <p>
                <b>Estimation</b> : Evaluation du bien donnée par le Commissaire-Priseur ; elle se traduit par une « fourchette » de prix. Le cas échéant, les estimations sont fournies à titre purement indicatif. Les estimations n'ont aucune valeur de garantie du prix d'adjudication. Les estimations sont exprimées en euros.
            </p>
            <p>
                <b>Exposition</b> : Présentation publique des produits vendus avant leur vente. Le lieu de l’exposition est précisé dans les détails de la vente.
            </p>
            <p>
                <b>Fiche d’identification d’un véhicule (FIV) </b> : Document officiel délivré par l’Agence nationale des titres sécurisés (ANTS), qui reprend les caractéristiques techniques d’un véhicule, telles qu’elles sont affichées sur la carte grise. Elle vous est délivrée en l’absence de la carte grise du véhicule. Ce document (accompagné du certificat de vente qui vous sera remis, mentionnant l’absence de carte grise) se substituera à la carte grise pour les formalités d’immatriculation de votre véhicule. Un véhicule vendu avec FIV ne pourra en principe être exporté qu’après formalités d’immatriculation sur le territoire français et obtention d’une nouvelle carte grise.
            </p>
            <p>
                <b>Folle enchère</b> : Ancienne dénomination de la « réitération des enchères » (voir ce paragraphe). La terminologie de l’article L.321-14 du code de commerce qui concerne les lots impayés remis en vente a été modifiée par le loi n° 2015-177 du 16 février 2015 relative à la modernisation et à la simplification du droit et des procédures dans les domaines de la justice et des affaires intérieures.
            </p>
            <p>
                <b>Hôtel des ventes</b> : Salle prévue pour l’organisation des ventes aux enchères publiques. L’Hôtel des ventes se situe Quartier Rochebrune, Route du Puy, 07100 Annonay. L’Hôtel des ventes est également l’établissement d’accueil des clients.
            </p>
            <p>
                <b>Lot</b> : Désigne un article (véhicule, matériel, etc.) ou ensemble d’article intégré au catalogue de vente. Les lots peuvent être consultés librement sur la page de la vente. Les descriptions des lots constituent l'expression par la SARL ARDECHE ENCHERES / Maître Grégory HELBOURG de sa perception des lots et non l'affirmation d'un fait. A ce titre, ces descriptions n'ont aucune valeur de preuve. Toutes les indications relatives à des coups/chocs/griffes/rayures/impacts…, un incident, un accident, une restauration, une mesure conservatoire… affectant un lot sont communiquées afin de faciliter son inspection par l'acheteur potentiel et restent soumises à l'entière appréciation de ce dernier. L'absence d'indication relative à des coups/chocs/griffes/rayures/impacts… un incident, un accident, une restauration ou une mesure conservatoire… n'implique nullement qu'un lot soit exempt de tout défaut, de toute restauration ou de toute mesure conservatoire... Une référence à coups/chocs/griffes/rayures/impacts… un défaut, à une restauration ou à une mesure conservatoire en particulier n'implique pas l'absence d'autres défauts, restaurations ou mesures conservatoires.  En tout état de cause, tous les lots sont vendus dans l'état où ils se trouvent au moment de leur adjudication avec leurs éventuelles imperfections.
            </p>
            <p>
                <b>M.E.C.</b> : Date de la première Mise En Circulation.
            </p>
            <p>
                <b>Mise à prix</b> : Somme fixée par le commissaire-priseur à laquelle il décide de débuter les enchères. Les mises à prix sont exprimées en euros.
            </p>
            <p>
                <b>Newsletter</b> : E-mail à vocation commerciale envoyé périodiquement aux clients abonnés. La Newsletter annonce la prochaine vente et les informations principales y afférentes. 
            </p>
            <p>
                <b>Opérateur de ventes volontaires (OVV)</b> : Professionnel dont l'activité consiste à organiser et réaliser la vente de biens neufs ou d'occasion au cours d'une vente publique aux enchères, et à s'assurer du bon déroulement de la transaction avec l'enchérisseur.
            </p>
            <p>
                <b>Règlement RGPD</b> : Le Règlement Général sur la Protection des Données encadre le traitement des données personnelles sur le territoire de l’Union européenne.
            </p>
            <p>
                <b>Site</b> : Site internet accessible à l’adresse : www.ardeche-encheres.com
            </p>
            <p>
                <b>TVA</b> : La Taxe sur la Valeur Ajoutée est une taxe dont le taux dépend de la nature du bien vendu et qui s’ajoute au prix de tous les produits qui y sont assujettis. Elle est en général incluse dans le montant des enchères prononcées. Dans certains cas et sur certains lots désignés de la vente, la TVA est récupérable par certains professionnels. Il sera demandé à l’acquéreur de présenter un extrait de KBis, un numéro de TVA intracommunautaire de la société et une pièce d’identité du représentant légal de la société.
            </p>
            <p>
                <b>Utilisateur</b> : Toute personne utilisant le site et ses services.
            </p>
            <p>
                <b>Vente aux enchères publiques</b> : <i>« ventes faisant intervenir un tiers, agissant comme mandataire du propriétaire ou de son représentant, pour proposer et adjuger un bien au mieux-disant des enchérisseurs à l'issue d'un procédé de mise en concurrence ouvert au public et transparent. Le mieux-disant des enchérisseurs acquiert le bien adjugé à son profit ; il est tenu d'en payer le prix »</i> (Art. L.320-2 C. Com).
            </p>
            <p>
                <b>Vente en live</b> : Vente aux enchères se déroulant en direct sur internet. Les acheteurs/internautes peuvent enchérir en même temps que les acheteurs présents en salle, lorsque la vente se fait simultanément. Pour enchérir en live, il est nécessaire de créer un compte sur le site <a href="https://www.interencheres.com/07001" target="_blank">www.interencheres.com</a>.
            </p>
            <p>
                <b>Vente en salle</b> : Vente aux enchères se déroulant dans des locaux de la SARL Ardèche Enchères, situés à l’adresse : Quartier Rochebrune, Route du Puy, 07100 Annonay.
            </p>
            <p>
                <b>Vente judiciaire</b> : Vente aux enchères prescrite par la loi ou par décision de justice.
            </p>
            <p>
                <b>Vente sur désignation</b> : Vente à distance d’un lot se trouvant en un lieu extérieur au centre de vente aux enchères.
            </p>
            <p>
                <b>Vente volontaire</b> : vente organisée à la requête, volontaire (et non judiciaire), de toute personne, particulier ou professionnel ayant fait le choix de vendre son bien aux enchères et par l'intermédiaire d'un opérateur de ventes volontaires.
            </p>

            <h3>
                ARTICLE 3 – DESCRIPTION DU SITE
            </h3>
            <p>
                Le présent site a pour objectif de rapprocher la SARL ARDECHE ENCHERES et ses clients dans un souci d’information et de présentation de la société. Il s’agit donc de permettre aux utilisateurs du site de rechercher les informations qui les intéressent concernant l’Hôtel des ventes et ses services et ventes.
            </p>
            <p>
                Tous les utilisateurs ont accès aux mêmes données :
            </p>
            <ul>
                <li>
                    -	Actualités, presse, ventes à venir
                </li>
                <li>
                    -	Informations de contact de la SARL ARDECHE ENCHERES, ainsi que du commissaire-priseur, plan d’accès aux locaux
                </li>
                <li>
                    -	Questions et réponses de la Foire Aux Questions (FAQ)
                </li>
                <li>
                    -	Informations des ventes à venir (lieu, lots, intitulé, description, etc.)
                </li>
                <li>
                    -	Toute autre information mise à disposition par l’éditeur. 
                </li>
            </ul>
            <p>
                La création d’un compte permet aux utilisateurs :
            </p>
            <ul>
                <li>
                    -	D’accéder à la Newsletter
                </li>
                <li>
                    -	De consulter les résultats des ventes de la SARL ARDECHE ENCHERES
                </li>
                <li>
                    -	De recevoir des alertes concernant les ventes et termes qu’ils mettent en favoris
                </li>
                <li>
                    -	De se faire connaître des services de la SARL ARDECHE ENCHERES
                </li>
                <li>
                    -	De contacter plus facilement l’éditeur.
                </li>
            </ul>
            <p>
                Il est ici précisé que la création d’un compte que www.ardeche-encheres.com ne permet pas de participer aux ventes présentées. 
                Pour participer à nos ventes live il faut vous inscrire sur <a href="https://www.interencheres.com/07001" target="_blank">www.interencheres.com</a>  
                onglet « LIVE » en haut de l’écran à droite.
            </p>

            <h3>
                ARTICLE 4 – ACcES AUX SERVICES
            </h3>
            <p>
                Le site est accessible gratuitement, depuis n’importe où, par tout utilisateur disposant d’un accès à internet. Tous les frais nécessaires pour l’accès aux services (matériel informatique, connexion internet, etc.) sont à la charge de l’utilisateur.
            </p>
            <p>
                Aucun identifiant n’est demandé pour l’accès aux services ne nécessitant pas la création d’un compte personnel. En revanche, l’accès aux services réservés aux membres s’effectue à l’aide d’un identifiant et d’un mot de passe. Pour cela, l’utilisateur doit inscrire, dans la case correspondante sur le formulaire d’inscription, une adresse email valide, les informations demandées et choisir un mot de passe. Ce mot de passe doit être suffisamment compliqué afin d’assurer la sécurité de son compte. A ce titre, il doit être composé d’au moins 8 caractères et d’au moins 3 types de caractères différents parmi les quatre types de caractères existants (majuscules, minuscules, chiffres et caractères spéciaux). Le propriétaire du compte prendra soin de le modifier régulièrement.  
            </p>
            <p>
                L’utilisateur est l’unique responsable du choix, de l'utilisation et de l’éventuelle communication de ses identifiants. Il doit toutefois informer l’éditeur en cas d’utilisation non autorisée de ses identifiants ou de son compte, ainsi que de la perte ou du vol de ces derniers. Le cas échéant, il lui appartient également de changer ses identifiants. L’éditeur n’est pas responsable de la perte des identifiants de l’utilisateur ou de son incapacité à les protéger. Il n’est pas non plus responsable de l’utilisation frauduleuse du compte.
            </p>

            <h3>
                ARTICLE 5 – DISPONIBILITE ET INTERRUPTION DES SERVICES
            </h3>
            <p>
                L’utilisation du site nécessite des moyens informatiques suffisants, en bon état de fonctionnement et ne contenant pas de virus.
            </p>
            <p>
                L’éditeur met en œuvre toutes les mesures propres à permettre un accès ininterrompu au site et ses services. Cependant, cet engagement présentant une obligation de moyen, non de résultat, l’éditeur ne peut être tenu pour responsable en cas d’interruption du service suite à une mise à jour, une panne, un bug, une erreur de fonctionnement, ni en cas d’inexactitude ou omission portant sur des informations disponibles sur le site. Il en est de même dans le cas où l’indisponibilité du site est due au fait d’un tiers ou de l’utilisateur et ne résulte pas de l’intervention de l’éditeur.
            </p>
            <p>
                Pour toute demande d’informations, de précisions ou pour toute réclamation, l’Utilisateur doit contacter l’éditeur à l’adresse :
            </p>
            <p>
                SARL ARDECHE ENCHERES <br/>
                Quartier Rochebrune <br/>
                Route du Puy <br/>
                07100 Annonay <br/>
                04.75.33.68.52 <br/>
                <a href="mailto:gregory.helbourg@sfr.fr">gregory.helbourg@sfr.fr</a>
            </p>

            <h3>
                ARTICLE 6 – OBLIGATIONS DE L’UTILISATEUR
            </h3>
            <p>
                L’utilisateur s’engage à utiliser le site de façon loyale, dans le respect des présentes conditions générales d’utilisation et des lois applicables. L’utilisateur s’engage à fournir à l’éditeur des informations personnelles exactes dans les formulaires qu’il remplit. L’opérateur de ventes volontaires ne sera pas tenu pour responsable des conséquences liées à la communication d’informations fausses, biaisées ou incomplètes. L’utilisateur veillera à mettre à jour ces informations en cas de changement de situation ou de fausse information.
            </p>
            <p>
                L’utilisateur s’assure que les informations qu’il communique ne portent pas atteinte aux droit des tiers, ni ne sont contraires aux lois et règlements en vigueur. A défaut, l’utilisateur en sera l’unique responsable et l’éditeur se réserve le droit de retirer le compte en question, de suspendre ou refuser les enchères de l’utilisateur concerné ou d’exercer toute action appropriée, y compris judiciaire.
            </p>

            <h3>
                ARTICLE 7 – LIENS HYPERTEXTE
            </h3>
            <p>
                Ce site comprend des liens hypertexte menant à des sites tiers ou d’autres sources externes ne dépendant ni du présent site, ni de son éditeur. Ces liens hypertexte ont notamment pour objectif de compléter les informations, d’orienter l’utilisateur, ou encore de lui permettre de contacter l’éditeur. Dans la mesure où l’éditeur n’a aucun pouvoir ni contrôle sur les sites visés par lesdits liens, il ne saurait être tenu pour responsable des services, des publicités, du contenu et de la mise à disposition de ces sites tiers, ni d’éventuels dysfonctionnements. De même l’éditeur n’est pas responsable de l’utilisation qui est faite sur ce site, ni des conséquences que cette dernière pourrait avoir.
            </p>
            <p>
                L’éditeur autorise les sites tiers à diffuser des liens hypertexte pointant vers le présent site et son contenu si, et dans la mesure où :
            </p>
            <ul>
                <li>
                    -	Il ne s’agit pas de deep-linking (technique du lien profond), mais que ce lien est accessible par l’ouverture d’une nouvelle fenêtre
                </li>
                <li>
                    -	Ce lien hypertexte n’est pas utilisé à des fins commerciales ou publicitaires
                </li>
                <li>
                    -	Les liens hypertextes ne proviennent pas de sites proposant des informations à caractère polémique, xénophobe, pornographique, ou portant 
                    atteinte à la sensibilité d’autrui.
                </li>
            </ul>
            <p>
                Les sites tiers souhaitant intégrer des liens hypertexte pointant vers le présent site peuvent contacter l’éditeur.
            </p>

            <h3>
                ARTICLE 9 – DROITS DE PROPRIETE INTELLECTUELLE 
            </h3>
            <p>
                Le site, ainsi que chacun des éléments qui le composent sont la propriété exclusive de l’éditeur conformément aux lois et règlements en vigueur. La mise à disposition du site ne saurait en aucun cas être assimilée à un transfert de propriété au profit de l’utilisateur Il n’est en aucun cas autorisé à quiconque de reproduire, représenter, modifier, adapter le site, en tout ou partie, sans l’accord écrit de l’éditeur. L’éditeur ne concède aucune licence et aucun autre droit que celui de consulter le site.
            </p>

            <h3>
                ARTICLE 10 – RESPONSABILITE DE L’EDITEUR 
            </h3>
            <p>
                L’Opérateur de Ventes Volontaires, ses dirigeants, employés ou mandataires, ne sauraient être tenus responsables des dommages indirects, des pertes commerciales, d’un manque à gagner ou d’une atteinte à l’image de marque résultant de l’utilisation des Services.
            </p>
            <p>
                Les illustrations et les descriptions publiées sur le site n’ont pas valeur de contrat, et sont susceptibles de modification jusqu’à la vente effective du/des lots. L’éditeur ne saurait être tenu pour responsable des conséquences d’attentes illégitimes des utilisateurs, lorsque les photos non contractuelles remplacent, temporairement ou non, celles du lot en question, parce que des informations complètes sont présentes sur place et que l’utilisateur peut à tout moment demander des informations supplémentaires en contactant l’opérateur de ventes volontaires.
            </p>
            <p>
                Les descriptions des lots constituent l'expression par la SARL ARDECHE ENCHERES / Maître Grégory HELBOURG de sa perception des lots et non l'affirmation d'un fait. A ce titre, ces descriptions n'ont aucune valeur de preuve. Toutes les indications relatives à des coups/chocs/griffes/rayures/impacts…, un incident, un accident, une restauration, une mesure conservatoire… affectant un lot sont communiquées afin de faciliter son inspection par l'acheteur potentiel et restent soumises à l'entière appréciation de ce dernier. L'absence d'indication relative à des coups/chocs/griffes/rayures/impacts… un incident, un accident, une restauration ou une mesure conservatoire… n'implique nullement qu'un lot soit exempt de tout défaut, de toute restauration ou de toute mesure conservatoire... Une référence à coups/chocs/griffes/rayures/impacts… un défaut, à une restauration ou à une mesure conservatoire en particulier n'implique pas l'absence d'autres défauts, restaurations ou mesures conservatoires.  En tout état de cause, tous les lots sont vendus dans l'état où ils se trouvent au moment de leur adjudication avec leurs éventuelles imperfections.
            </p>
            <p>
                Les utilisateurs envisageant d’enchérir sur (un)des lot(s) sont invités à participer à l’exposition.
            </p>
            
            <h3>
                ARTICLE 11 - CLAUSE ATTRIBUTIVE DE JURIDICTION ET CHOIX DE LOI
            </h3>
            <p>
                Tout litige relative à l’utilisation du site, ses informations, les obligations qui en découlent, ainsi que les présentes CGU et tous les litiges qui en découlent relèvent de la compétence des juridictions françaises. La loi française est applicable auxdits litiges.
            </p>
        </div>
    </div>
</div>