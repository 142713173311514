import { Component, OnInit } from '@angular/core';
import { SalesService } from '../services/sales.service';
import * as models from '../services/models';
import { combineLatest, Subscription } from 'rxjs';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sales',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

  constructor(
    private userService: UserService,
    private salesService: SalesService,
    public dialog: MatDialog,
    public router: Router,
  ) { }

  public loading: boolean;

  public faCalendarAlt = faCalendarAlt;

  public results: models.Sale[];

  private subscriptions: Subscription[] = [];

  public logged: boolean = false;
  public user: models.User;

  ngOnInit(): void {
    window.scroll(0, 0);
    this.loading = true;
    this.subscriptions.push(
      this.userService.getUser$().subscribe(user => {
        if (!user) {
          this.router.navigate(['/home'])
        }
      }),
      combineLatest([
        this.salesService.getResults$(),
        this.userService.getUser$(),
      ]).subscribe(([results, user]) => {
        this.results = results.sort((a, b) => {
          if (a.date < b.date)
            return 1;
          if (a.date > b.date)
            return -1;
          return 0;
        });

        this.results.map(sale => {
          let fileName: string;
          if (sale.default_picture) {
            fileName = sale.default_picture;
          } else {
            if (typeof sale.items[0]?.pictures === 'string' && sale.items[0]?.pictures) {
              fileName = sale.items[0]?.pictures;
            } else if (typeof sale.items[0]?.pictures === 'object' && sale.items[0]?.pictures) {
              fileName = sale.items[0]?.pictures[0];
            }
          }
          this.salesService.getItemPicture$(sale.id, fileName).subscribe((url) => {
            this.results = this.results.map(newSale => {
              if (newSale.id === sale.id) {
                newSale.picture = url;
              }
              return newSale
            })
          })
        })

        this.loading = false;
      }),
    );
  }

}
