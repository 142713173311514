import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  public hide = true;
  public email = new FormControl('', [Validators.required, Validators.email]);
  public password = new FormControl('', [Validators.required]);
  public errorMessage: string;
  public emailSent: boolean = false;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.email) {
        this.email.setValue(params.email);
      }
    })
  }

  public sendResetEmail() {
    this.userService.sendPasswordResetEmail(this.email.value)
    .pipe(catchError(error => {
      if (error.message) {
        this.errorMessage = error.message;
      }
      return error
    }))
    .subscribe((_error) => {
      this.emailSent = true;
    })
  }

  public getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Veuillez saisir votre adresse e-mail';
    }

    return this.email.hasError('email') ? "L'e-mail est invalide" : "";
  }

  public getPasswordErrorMessage() {
    if (this.password.hasError('required')) {
      return 'Veuillez saisir votre mot de passe';
    }
  }

  public goToLogin(event) {
    event.stopPropagation();
    this.router.navigate(['/account']);
  }

}
