import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(
    private userService: UserService,
  ) { }

  canActivate() {
    return this.userService.getUser$()
    .pipe(map(user => user && user.admin))
  }
}
