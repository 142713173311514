import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import { combineLatest, empty, from, Observable, of, ReplaySubject, } from 'rxjs';
import { concatMap, expand, first, switchMap, toArray } from 'rxjs/operators';
import * as models from '../services/models';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(
    private datePipe: DatePipe,
  ) { }

  private db = firebase.database();
  private storage = firebase.storage();
  private salesInitialized: boolean = false;
  private sales: ReplaySubject<models.Sale[]> = new ReplaySubject();
  private salesMap: { [id: number]: ReplaySubject<models.Sale> } = {};

  private resultsInitialized: boolean = false;
  private results: ReplaySubject<models.Sale[]> = new ReplaySubject();
  private resultsMap: { [id: number]: ReplaySubject<models.Sale> } = {};

  private highlightsInitialized: boolean = false;
  private highlights: ReplaySubject<models.Sale[]> = new ReplaySubject();

  public getSale$(saleId, refresh?: boolean): Observable<models.Sale> {
    if (!this.salesMap[saleId] || refresh) {
      if (!this.salesMap[saleId]) {
        this.salesMap[saleId] = new ReplaySubject(1);
      }
      this.salesMap[saleId].next(null)
      this.db.ref('sales/' + saleId).once('value').then((snapshot) => {
        if (snapshot.exists()) {
          const s = snapshot.val()
          const sale = {
            ...snapshot.val(),
            site: s.site || '',
            access_info: s.access_info || '',
            register_link: s.register_link || '',
            exposition: s.exposition || '',
            CGV_resume: s.CGV_resume || '',
            CGV_link: s.CGV_link || '',
            items: s.items?.map(i => {
              if (i.vehicule) {
                i.name = i.vehicule.brand + ' ' + i.vehicule.model;
              }
              i.matchFilter = true;
              return i;
            }).sort((a, b) => a.number - b.number) || [],
          };
          this.salesMap[saleId].next(sale);
        } else {
          this.salesMap[saleId].next(null);
        }
      });
    }
    return this.salesMap[saleId];
  }

  public getResult$(saleId): Observable<models.Sale> {
    if (!this.resultsMap[saleId]) {
      this.resultsMap[saleId] = new ReplaySubject(1);
      this.db.ref('results/' + saleId).once('value', (snapshot) => {
        if (snapshot.exists()) {
          const sale = snapshot.val();
          sale.items = sale.items.map(i => {
            if (i.vehicule) {
              i.name = i.vehicule.brand + ' ' + i.vehicule.model;
            }
            i.matchFilter = true;
            return i;
          }).sort((a, b) => a.number - b.number);
          this.resultsMap[saleId].next(sale);
        } else {
          this.resultsMap[saleId].next(null);
        }
      });
    }
    return this.resultsMap[saleId];
  }

  public getSales$(refresh?: boolean): Observable<models.Sale[]> {
    if (!this.salesInitialized || refresh) {
      const now = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.db.ref('sales').orderByChild('date').startAt(now).once('value').then((snapshot) => {
        if (snapshot.exists()) {
          const sales = Object.keys(
            snapshot.val()
          ).map(k =>
            snapshot.val()[k]
          ).map(s => ({
            ...s,
            items: s.items?.map(i => {
              if (i.vehicule) {
                i.name = i.vehicule.brand + ' ' + i.vehicule.model;
              }
              return i;
            }).sort((a, b) => a.number - b.number) || [],
          }));
          this.sales.next(sales);
          this.salesInitialized = true;
        } else {
          this.sales.next([]);
          this.salesInitialized = true;
        }
      });
    }
    return this.sales;
  }

  public getResults$(refresh?: boolean): Observable<models.Sale[]> {
    if (!this.resultsInitialized || refresh) {
      this.db.ref('results').once('value').then((snapshot) => {
        if (snapshot.exists()) {
          const results = Object.keys(
            snapshot.val()
          ).map(k =>
            snapshot.val()[k]
          ).map(s => ({
            ...s,
            items: s.items?.map(i => {
              if (i.vehicule) {
                i.name = i.vehicule.brand + ' ' + i.vehicule.model;
              }
              return i;
            }).sort((a, b) => a.number - b.number),
          })) || [];
          this.results.next(results);
          this.resultsInitialized = true;
        } else {
          this.results.next([]);
          this.resultsInitialized = true;
        }
      });
    }
    return this.results;
  }

  public deleteResult(saleId: string) {
    this.sales.pipe(first()).subscribe(sales => {
      const newSales = sales.filter(f => f.id !== saleId)
      this.sales.next(newSales)
      this.db.ref('results/' + saleId).remove().then(() => { });
    })
  }

  public highlightResultItem(saleId: string, itemId: string, highlight: boolean) {
    this.db.ref('results/' + saleId).once('value').then((snapshot) => {
      if (snapshot.exists()) {
        const items = snapshot.val().items.map(i => {
          if (i.id === itemId) {
            i.highlighted = highlight;
          }
          return i
        });
        this.db.ref('results/' + saleId + '/items').set(items).then(() => { });
        if (highlight) {
          this.db.ref('highlights/' + saleId + '/' + itemId).set(true).then(() => { });
        } else {
          this.db.ref('highlights/' + saleId + '/' + itemId).remove().then(() => { });
        }
      }
    });
  }

  public highlightNextSale(saleId: number, highlighted: boolean): void {
    this.db.ref('sales/' + saleId + '/highlighted').set(highlighted).then(() => {
      location.reload();
    });
  }

  public deleteSale(saleId: string) {
    this.sales.pipe(first()).subscribe(sales => {
      const newSales = sales.filter(f => f.id !== saleId)
      this.sales.next(newSales)
      this.db.ref('sales/' + saleId).remove().then(() => { });
    })
  }

  public getItemPicture$(saleId, fileName): Observable<string> {
    return new Observable((observer) => {
      if (fileName) {
        this.storage.ref(saleId).child(fileName).getDownloadURL().then((url) => {
          observer.next(url);
        })
      } else {
        observer.next('');
      }
    })
  }

  public getPicturesByItem$(sale: models.Sale, allItems: boolean, allPictures: boolean): Observable<models.Sale> {
    return new Observable((observer) => {
      let filenamesByItem: {
        itemId: string,
        filename: string,
      }[] = [];

      from(this.storage.ref(sale.id.toString()).list()).pipe(
        expand((res: firebase.storage.ListResult) =>
          res.nextPageToken
            ? from(this.storage.ref(sale.id.toString()).list({ pageToken: res.nextPageToken }))
            : empty(),
        ),
        concatMap(({ items }) => [items]),
        concatMap((page) => page),
        toArray(),
      ).subscribe((items) => {
        const existingImgs = items.map(i =>
          i['_delegate']['_location']['path'].replace(sale.id.toString() + '/', '')
        )
        let promises: Promise<any>[] = [];
        if (sale.default_picture) {
          promises.push(this.storage.ref(sale.id.toString() + '/' + sale.default_picture).getDownloadURL());
        }
        sale.items.map((item, i) => {
          if (allItems || (!allItems && i === 0)) {
            if (typeof item.pictures === 'string' && item.pictures && existingImgs.find(i => i === item.pictures)) {
              filenamesByItem.push({ itemId: item.id, filename: item.pictures });
              promises.push(this.storage.ref(sale.id.toString()).child(item.pictures).getDownloadURL());
            } else if (typeof item.pictures === 'object' && item.pictures) {
              let pct = item.pictures.filter(p => existingImgs.find(i => i === p));
              if (!allPictures) {
                pct = [pct[0]];
              }
              // @ts-ignore
              pct.map(picture => {
                if (picture) {
                  filenamesByItem.push({ itemId: item.id, filename: picture });
                  promises.push(this.storage.ref(sale.id.toString()).child(picture).getDownloadURL());
                }
              })
            }
          }
        })
        Promise.all(promises).then((urls) => {
          urls.map((url, i) => {
            if (sale.default_picture && i === 0) {
              sale = {
                ...sale,
                default_picture: url,
              }
            } else {
              const itemToUpdate = filenamesByItem.find((res) => url.includes(res.filename)).itemId;
              sale = {
                ...sale,
                items: sale.items.map(item => {
                  if (item.id === itemToUpdate) {
                    let newPictures;
                    if (typeof item.pictures === 'string' && item.pictures) {
                      newPictures = [url];
                    } else if (typeof item.pictures === 'object' && item.pictures) {
                      newPictures = item.pictures.map(picture => {
                        if (url.includes(picture)) {
                          return url;
                        } else {
                          return picture;
                        }
                      });
                    }

                    return {
                      ...item,
                      matchFilter: true,
                      pictures: newPictures,
                    }
                  } else {
                    return item
                  }
                }),
              }
            }
          });
          observer.next(sale);
        }, (err) => {
          observer.next(sale);
        });
      });
    });
  }

  public updateSaleField(saleId, field: string, value) {
    this.db.ref('sales/' + saleId + '/' + field).set(value);
  }

  public getFileUrl$(fileName): Observable<string> {
    return new Observable((observer) => {
      this.storage.ref(fileName).getDownloadURL().then((url) => {
        observer.next(url)
      })
    })
  }

  public uploadCGVFile(saleId: string, file: File): Observable<string> {
    return new Observable((observer) => {
      this.storage.ref(saleId + '/' + file.name).put(file).then(() => {
        this.getFileUrl$(saleId + '/' + file.name).subscribe((url) => {
          this.updateSaleField(saleId, 'CGV_link', url);
          observer.next(url)
        })
      })
    })
  }

  public getHighlights$(): Observable<models.Sale[]> {
    if (!this.highlightsInitialized) {
      this.getResults$().pipe(
        switchMap((results) => {
          const highlithedResults =
            results
              .filter(r => r.items.some(i => i.highlighted))
              .map(r => ({
                ...r,
                items: r.items.filter(i =>
                  i.highlighted
                )
              }));
          return combineLatest(highlithedResults.map(r => this.getPicturesByItem$(r, true, false)));
        })
      ).subscribe((results) => {
        this.highlights.next(
          results.map(r => ({
            ...r,
            items: r.items.map(i => ({
              ...i,
              pictures: i.pictures && i.pictures.length
                ? [i.pictures[0]]
                : []
            }))
          }))
        );
        this.highlightsInitialized = true;
      })
    }
    return this.highlights;
  }

  public setNextSale(date: Date): void {
    this.db.ref('next_sale').set(date.getTime()).then(() => { });
  }

  public deleteNextSale(): void {
    this.db.ref('next_sale').remove().then(() => { });
  }

  private _nextSale: ReplaySubject<Date>;
  public getNextSale(): Observable<Date> {
    if (!this._nextSale) {
      this._nextSale = new ReplaySubject(1);
      this.db.ref('next_sale').once('value').then((snapshot) => {
        if (snapshot.exists()) {
          this._nextSale.next(snapshot.val())
        } else {
          this._nextSale.next(null)
        }
      });
    }

    return this._nextSale;
  }

  public changeSalePicture(saleId: string, pictureUrl: string): void {
    this.db.ref('sales/' + saleId + '/default_picture').set(pictureUrl).then(() => { });
  }

  public changeResultPicture(saleId: string, pictureUrl: string): void {
    this.db.ref('results/' + saleId + '/default_picture').set(pictureUrl).then(() => { });
  }

  public getItemPictures$(saleId: string, item: models.Item): Observable<models.Item> {
    return new Observable((observer) => {
      from(this.storage.ref(saleId.toString()).list()).pipe(
        expand((res: firebase.storage.ListResult) =>
          res.nextPageToken
            ? from(this.storage.ref(saleId.toString()).list({ pageToken: res.nextPageToken }))
            : empty(),
        ),
        concatMap(({ items }) => [items]),
        concatMap((page) => page),
        toArray(),
      ).subscribe((items) => {
        const existingImgs = items.map(i =>
          i['_delegate']['_location']['path'].replace(saleId.toString() + '/', '')
        )
        let promises: Promise<any>[] = [];
        if (typeof item.pictures === 'string' && item.pictures && existingImgs.find(i => i === item.pictures)) {
          promises.push(this.storage.ref(saleId.toString()).child(item.pictures).getDownloadURL());
        } else if (typeof item.pictures === 'object' && item.pictures) {
          let pct = item.pictures.filter(p => existingImgs.find(i => i === p));
          // @ts-ignore
          pct.map(picture => {
            if (picture) {
              promises.push(this.storage.ref(saleId.toString()).child(picture).getDownloadURL());
            }
          })
        }
        Promise.all(promises).then((urls) => {
          urls.map((url) => {
            let newPictures;
            if (typeof item.pictures === 'string' && item.pictures) {
              newPictures = [url];
            } else if (typeof item.pictures === 'object' && item.pictures) {
              newPictures = item.pictures.map(picture => {
                if (url.includes(picture)) {
                  return url;
                } else {
                  return picture;
                }
              });
            }
            item = {
              ...item,
              matchFilter: true,
              pictures: newPictures,
            };
          });
          observer.next(item)
        });
      });
    });
  }

  /*public temp() {
    const saleIds = ["1740", "1742", "1744", "1746", "1748", "1749", "1751", "1753", 
      "1755", "1756", "1757", "1758", "1760", "1762", "1764", "1765", "1767", "1768", 
      "1770", "1771", "1781", "1784"];
    const fields = ['access_info', 'terms_of_sale', 'register_link', 'exposition', 'CGV_resume', 'CGV_link'];

    saleIds.forEach(saleId => {
      fields.forEach(field => {
        this.db.ref('sales/' + saleId + '/' + field).set('').then(() => { console.log("ok") })
      })
    })
  }*/

}
