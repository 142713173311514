<section class="about about-2 py-100-70" id="about-us">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="text-box">
                    <div class="sec-title sec-title-2">
                        <h2>Maître</h2>
                        <h3>Gregory Helbourg</h3>
                        <p>titulaire de l’Etude depuis 2011 après un long parcours dans différentes salles de vente, vous propose plus d’une centaine de ventes chaque année. Celles-ci peuvent avoir lieu directement sur site ou à l’Hôtel des Ventes.
                            </p>
                    </div>
                    
                    <ul class="core-about">
                        <li><i class="fas fa-check"></i> <h4>Titulaire de l'étude depuis 2011</h4></li>
                        <li><i class="fas fa-check"></i> <h4>Habilité à diriger des ventes</h4></li>
                        <li><i class="fas fa-check"></i> <h4>Effectuer des expertises</h4></li>
                    </ul>
                    <p>N’hésitez pas à nous contacter. Notre équipe, dynamique et de taille humaine, est à votre écoute.</p>
                    <a class="btn-1" routerLink="/contact">Nous contacter</a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="img-box">
                    <div class="about-img">
                        <img class="img-fluid" src="assets/images/gregory_helbourg.jpg" alt="02 About">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>