<div class="sec-title sec-title-3">
    <div class="row login-fields-container justify-content-end">
        <div class="col-lg-10" *ngIf="!relog">
            <h2 class="title">Je me connecte</h2>
        </div>
        <div class="col-lg-10">
            <mat-form-field class="w-100">
                <input matInput placeholder="E-mail" type="email" [formControl]="email" required>
            </mat-form-field>
            <span *ngIf="email.invalid && email.touched" class="error-message">{{getEmailErrorMessage()}}</span>
        </div>
        <div class="col-lg-10">
            <mat-form-field class="w-100">
                <input matInput placeholder="Mot de passe" [type]="hide ? 'password' : 'text'" [formControl]="password" required>
                <mat-icon matSuffix (click)="hide = !hide" class="hide-password-button">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
            <span *ngIf="password.invalid && password.touched" class="error-message">{{ getPasswordErrorMessage() }}</span>
        </div>
        <div class="col-lg-10">
            <div class="quote-item">
                <a class="btn-1" (click)="onSubmit()">Se connecter</a>
                <span *ngIf="errorMessage" class="error-message"> {{ errorMessage}} </span>
            </div>
        </div>
        <div class="col-lg-10">
            <span class="password-reset-sentence">Mot de passe oublié ? <a class="password-reset-link" (click)="goToPasswordReset($event)">cliquez ici</a></span>
        </div>
    </div>
</div>