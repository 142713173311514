import { Component, OnInit } from '@angular/core';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { SalesService } from '../services/sales.service';
import * as models from '../services/models';
import { Subscription } from 'rxjs';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {


  constructor(
    private salesService: SalesService,
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  public loading: boolean;

  public faStar = farStar;
  public sale: models.Sale;
  public tags: {
    id: number,
    tag: string,
    class: string,
  }[] = [];
  public isAdmin: boolean = false;

  public search: any;
  private tagFilters: string[] = [];

  public faCalendarAlt = faCalendarAlt;

  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    window.scroll(0, 0);
    this.loading = true;
    this.subscriptions.push(
      this.userService.getUser$().subscribe(user => this.isAdmin = user && user.admin),
      this.route.params.subscribe(params => {
        if (params.sale) {
          this.subscriptions.push(
            this.salesService.getResult$(params.sale).subscribe((sale: models.Sale) => {
              if (sale) {
                this.salesService.getPicturesByItem$(sale, true, false).subscribe((saleUpdatedWithPictures) => {
                  this.sale = saleUpdatedWithPictures;
                  this.sale.picture = typeof this.sale.items[0]?.pictures === 'string' && this.sale.items[0]?.pictures
                    ? this.sale.items[0]?.pictures : this.sale.items[0]?.pictures[0];
                  this.tags = [...new Set(this.sale.items.map(item => this.formateString(item.category)))].map((tag, id) => ({ id, tag, class: "tag-unselected" }));
                  this.loading = false;
                })
              }
            }),
          );
        }
      }),
    );
  }

  public filterItems(event): void {
    this.search = event.target.value;
    this.applyFilters();
  }

  public toggleTagFilter(tag: string, id: number): void {
    if (!this.tagFilters.includes(tag)) {
      this.tagFilters.push(tag);
      this.tags = this.tags.map(t => {
        if (t.id === id) {
          t.class = "tag-selected";
        }
        return t
      });
    } else {
      this.tagFilters = this.tagFilters.filter(t => t !== tag);
      this.tags = this.tags.map(t => {
        if (t.id === id) {
          t.class = "tag-unselected";
        }
        return t
      });
    }
    this.applyFilters();
  }

  public applyFilters(): void {
    this.sale.items = this.sale.items.map(item => {
      if ((this.tagFilters && this.tagFilters.length && this.tagFilters.includes(this.formateString(item.category)) && this.search && this.formateString(item.name).includes(this.formateString(this.search))
        || (this.tagFilters && this.tagFilters.length && this.tagFilters.includes(this.formateString(item.category)) && !this.search)
        || (this.search && this.formateString(item.name).includes(this.formateString(this.search)) && (!this.tagFilters || !this.tagFilters.length))
        || !this.search && (!this.tagFilters || !this.tagFilters.length))
      ) {
        item.matchFilter = true;
      } else {
        item.matchFilter = false;
      }
      return item;
    });
  }

  public formateString(string: string) {
    return string.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  public highlightResult(saleId: string, itemId: string, highlight: boolean) {
    this.sale.items = this.sale.items.map(i => ({ ...i, highlighted: i.id === itemId ? highlight : i.highlighted || false }))
    this.salesService.highlightResultItem(saleId, itemId, highlight);
  }

}