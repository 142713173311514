import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/database';
import { Observable, ReplaySubject, } from 'rxjs';
import { first } from 'rxjs/operators';
import * as models from '../services/models';

@Injectable({
  providedIn: 'root'
})
export class FaqsService {

  constructor(
  ) { }

  private db = firebase.database();
  private faqsInitialized: boolean = false;
  private faqs: ReplaySubject<models.Faq[]> = new ReplaySubject();

  public getFaqs$(refresh?: boolean): Observable<models.Faq[]> {
    if (!this.faqsInitialized || refresh) {
      this.db.ref('faqs').once('value').then((snapshot) => {
        if (snapshot.exists()) {
          this.faqs.next(snapshot.val());
          this.faqsInitialized = true;
        }
      });
    }
    return this.faqs;
  }

  public updateFaq(faq: models.Faq) {
    this.faqs.pipe(first()).subscribe(faqs => {
      let newFaqs;
      if (faqs.find(f => f.id === faq.id)) {
        newFaqs = faqs.map(f => {
          if (f.id === faq.id) {
            return faq
          }
          return f
        })
      } else {
        faqs.push(faq)
        newFaqs = faqs;
      }
      this.faqs.next(newFaqs)

      this.db.ref('faqs/').set(newFaqs).then(() => { });
    })
  }

  public deleteFaq(faqId: string) {
    this.faqs.pipe(first()).subscribe(faqs => {
      const newFaqs = faqs.filter(f => f.id !== faqId);

      this.faqs.next(newFaqs)

      this.db.ref('faqs/').set(newFaqs).then(() => { });
    })
  }

}
