<div class="container py-100-70">
    <div class="row">
        <div class="col-lg-6">
            <div class="sec-title sec-title-2">
                <h2>Ventes à venir ({{incomingSales.length ? incomingSales.length : nextSaleDate ? 1 : 0}})</h2>
            </div>
        </div>
    </div>
</div>

<ng-template [ngIf]="!loading" [ngIfElse]="firstprogressspinner">
    <ng-template [ngIf]="incomingSales && incomingSales.length > 0" [ngIfElse]="noincomingsale">
        <section class="about pb-5" *ngFor="let sale of incomingSales">
            <div class="container" [routerLink]="['/sale', sale.id]">
                <div class="row sale-row py-3">
                    <div class="col-lg-5">
                        <div class="img-box">
                            <div class="sale-img" [style]="{'background-image': 'url(' + sale.picture + ')'}">
                            </div>
                            <div class="case-about">
                                <div class="counter">{{ sale.items.length }}</div>
                                <h5>Lots</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 offset-lg-1">
                        <div class="text-box">
                            <div class="sec-title">
                                <fa-icon [icon]="faCalendarAlt" class="calendar-icon"></fa-icon> <h2>{{ sale.date | date: 'EEEE dd MMMM y' }} à {{ sale.date | date:'shortTime' }}</h2>
                                <h3 class="sale-title">{{ sale.title }}</h3>
                                <h5 class="sale-adress"><i class="flaticon-location sale-location-icon"></i> {{ sale.site }}</h5>
                            </div>
                            <div class="row see-more-container">
                                <div class="col-sm-6">
                                    <a class="btn-1" [routerLink]="['/sale', sale.id]">Plus d'info sur la vente</a>
                                </div>
                            </div>
                        </div>
                        <div class="fav-button-container">
                            <ng-template [ngIf]="sale.favorited" [ngIfElse]="notfavorited">
                                <button mat-icon-button (click)="$event.preventDefault();$event.stopPropagation();unfavSale(sale.id)">
                                    <mat-icon 
                                        matTooltip="Ajouter à mes favoris" 
                                        matTooltipPosition="left"
                                        class="fav-icon">
                                        star
                                    </mat-icon>
                                </button>
                            </ng-template>
                            <ng-template #notfavorited>
                                <button mat-icon-button (click)="$event.preventDefault();$event.stopPropagation();favSale(sale.id)">
                                    <mat-icon 
                                        matTooltip="Ajouter à mes favoris" 
                                        matTooltipPosition="left"
                                        class="fav-icon">
                                        star_border
                                    </mat-icon>
                                </button>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </ng-template>
    <ng-template #noincomingsale>
        <div class="container no-sale-container pb-5" *ngIf="!nextSaleDate">
            <div class="row">
                <div class="col-lg-6">
                    <span>Aucune vente à venir pour le moment.</span>
                </div>
            </div>
        </div>
        <div class="container pb-5" *ngIf="nextSaleDate">
            <div class="row py-3">
                <div class="col-lg-12 next-sale-fake">
                    <div class="text-box">
                        <div class="sec-title">
                            <fa-icon [icon]="faCalendarAlt" class="calendar-icon"></fa-icon> <h2>{{ nextSaleDate | date: 'EEEE dd MMMM y' }}</h2>
                            <h5 class="sale-adress">Description et photos des lots à venir</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-template>
<ng-template #firstprogressspinner>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 spinner-container">
                <div class="lil-progress-spinner"></div>
            </div>
        </div>
    </div>
</ng-template>
