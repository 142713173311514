import { Component, OnInit } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeExtraFr from '@angular/common/locales/extra/fr';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { CookiesManagementService } from './services/cookies.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public cookiesConsentment: boolean = true;

  constructor(
    private cookiesManagementService: CookiesManagementService,
  ) {
    firebase.initializeApp({
      apiKey: "AIzaSyAhaYPgQONIVZoXpC8rc1-maIQNYQric9A",
      authDomain: "ardeche-encheres-ec6e2.firebaseapp.com",
      databaseURL: "https://ardeche-encheres-ec6e2-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "ardeche-encheres-ec6e2",
      storageBucket: "ardeche-encheres-ec6e2.appspot.com",
      messagingSenderId: "675290982169",
      appId: "1:675290982169:web:1e31770f08e1f3a2904788",
      measurementId: "G-RE675HKWMN"
    });
    firebase.analytics();
    registerLocaleData(localeFr, 'fr', localeExtraFr);
  }

  ngOnInit(): void {
    this.cookiesManagementService.cookiesConsentment$.subscribe((consentment) => {
      this.cookiesConsentment = consentment;
    });
  }
}
