<section class="faqs faqs-page py-100-70">
    <div class="container">
        <div class="sec-title sec-title-2">
            <div class="row">
                <div class="col-lg-5">
                    <h2>Foire Aux Questions</h2>
                    <h3>Vos questions, nos réponses</h3>
                </div>
            </div>
        </div>
        <div class="row faq faq-page" id="faqSite">
            <div class="col-lg-6 pb-3" *ngFor="let faq of faqs">
                <mat-accordion>
                    <mat-expansion-panel 
                        (opened)="faq.expanded = true"
                        (closed)="faq.expanded = false"
                        [expanded]=faq.expanded>
                        <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ faq.question }}
                        </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p [innerHtml]="faq.answer"></p>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            
        </div>
    </div>
</section>
