<div class="sec-title sec-title-2 next-sales-title">
    <div class="container">
        <div class='row justify-content-center'>
            <div class="col-lg-12">
                <h2>Prochaines ventes</h2>
            </div>
        </div>
    </div>
</div>
<ng-template [ngIf]="!loading" [ngIfElse]="progressspinner">
    <section class="about" *ngIf="nextSale">
        <div class="container">
            <div class="row sale-row py-3">
                <div class="col-lg-4">
                    <div class="img-box">
                        <div class="sale-img" [style]="{'background-image': 'url(' + nextSale.picture + ')'}">
                        </div>
                        <div class="case-about">
                            <div class="counter">{{ nextSale.items.length }}</div>
                            <h5>Lots</h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 offset-lg-2">
                    <div class="text-box">
                        <div class="sec-title">
                            <fa-icon [icon]="faCalendarAlt" class="calendar-icon"></fa-icon> <h2>{{ nextSale.date | date: 'EEEE dd MMMM y' }} à {{ nextSale.date | date:'shortTime' }}</h2>
                            <h3 class="sale-title">{{ nextSale.title }}</h3>
                            <h5 class="sale-adress"><i class="flaticon-location sale-location-icon"></i> {{ nextSale.site }}</h5>
                        </div>
                        <div class="row see-more-container">
                            <div class="col-sm-6">
                                <a class="btn-1" [routerLink]="['/sale', nextSale.id]">Plus d'info sur la vente</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row sale-row py-3 my-5 other-sales-container" [routerLink]="['/sales']" *ngIf="incomingSales.length > 1">
                <div class="col-lg-6 other-sales">
                    {{ incomingSales.length-1 }} autre(s) vente(s) en approche.
                </div>
                <div class="col-lg-6 p-0">
                    <div class="col-sm-6">
                        <button mat-stroked-button [routerLink]="['/sales']" class="options-buttons"> 
                            <mat-icon>arrow_forward</mat-icon> Voir toutes les prochaines ventes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="about" *ngIf="!nextSale">
        <div class="container">
            <div class="row py-3">
                <div class="col-lg-12 next-sale-fake">
                    <div class="text-box">
                        <div class="sec-title" *ngIf="nextSaleDate && nextSaleDate >= currentDate">
                            <fa-icon [icon]="faCalendarAlt" class="calendar-icon"></fa-icon> <h2>{{ nextSaleDate | date: 'EEEE dd MMMM y' }}</h2>
                            <h5 class="sale-adress">Descriptifs et photos des lots à venir</h5>
                        </div>
                        <div *ngIf="!nextSaleDate || nextSaleDate < currentDate">
                            <h5 class="sale-adress"><fa-icon [icon]="faCalendarAlt" class="calendar-icon"></fa-icon> La date de la prochaine vente vous sera prochainement communiquée</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-template>
<ng-template #progressspinner>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 spinner-container">
                <div class="lil-progress-spinner"></div>
            </div>
        </div>
    </div>
</ng-template>

<div class="sec-title sec-title-2 next-sales-title" *ngIf="highlights">
    <div class="container">
        <div class='row justify-content-center'>
            <div class="col-lg-12">
                <h2>Résultats des lots phares</h2>
            </div>
        </div>
    </div>
</div>
<ng-template [ngIf]="!highlightsloading" [ngIfElse]="secondprogressspinner">
    <section class="about" *ngIf="highlights && highlights.length">
        <div class='row justify-content-center m-0'>
            <div class="col-md-8">
                <div class="blog-item">
                    <div class="text-box2">
                        <div class='row highlights-row py-3'>
                            <ng-container *ngFor="let highlight of highlights">
                                <div *ngFor="let item of highlight.items" class="col-md-4">
                                    <div class="row sale-row py-1" [routerLink]="['/result', highlight.id, item.id]">
                                        <div class="col-md-5 img-container">
                                            <div class="open-post">
                                                <img class="img-fluid" *ngIf="item.pictures && item.pictures.length" [src]="item.pictures[0]">
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <span class="sale-name">{{ item.name | slice:0:40 }}</span>
                                            <p>Catégorie : {{ item.category }}</p>
                                            <span class="estimation">Estimation: {{ item.startprice && item.startprice > 0 ? item.startprice + ' €'  : '-' }}</span> <br/>
                                            <span class="estimation"><b>Prix d'adjudication:</b> {{ item.auctionprice && item.auctionprice > 0 ? item.auctionprice + ' €'  : '-' }}</span>
                                        </div>
                                        <div class="highlight-button-container" *ngIf="isAdmin">
                                            <ng-template [ngIf]="item.highlighted" [ngIfElse]="nothighlighted">
                                                <button mat-icon-button (click)="$event.preventDefault();$event.stopPropagation();highlightResult($event, highlight.id, item.id, false)">
                                                    <mat-icon 
                                                        matTooltip="Retirer des résultats phares"
                                                        matTooltipPosition="left"
                                                        class="highlight-icon">
                                                        link
                                                    </mat-icon>
                                                </button>
                                            </ng-template>
                                            <ng-template #nothighlighted>
                                                <button mat-icon-button (click)="$event.preventDefault();$event.stopPropagation();highlightResult($event, highlight.id, item.id, true)">
                                                    <mat-icon 
                                                        matTooltip="Ajouter aux résultats phares" 
                                                        matTooltipPosition="left"
                                                        class="highlight-icon">
                                                        link_off
                                                    </mat-icon>
                                                </button>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="about" *ngIf="highlights && !highlights.length">
        <div class='row justify-content-center m-0'>
            <div class="col-md-8 no-result">
                Aucun
            </div>
        </div>
    </section>
</ng-template>
<ng-template #secondprogressspinner>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 spinner-container">
                <div class="lil-progress-spinner"></div>
            </div>
        </div>
    </div>
</ng-template>