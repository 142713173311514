import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { SalesComponent } from './sales/sales.component';
import { ItemComponent } from './item/item.component';
import { FaqComponent } from './faq/faq.component';
import { WhoComponent } from './who/who.component';
import { ResultsComponent } from './results/results.component';
import { ResultComponent } from './result/result.component';
import { ResultItemComponent } from './result-item/result-item.component';
import { SaleComponent } from './sale/sale.component';
import { AccountComponent } from './account/account.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AdminGuard } from './guards/admin.guard';
import { CguComponent } from './cgu/cgu.component';
import { TermsComponent } from './terms/terms.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'account',
    component: AccountComponent,
  },
  {
    path: 'sales',
    component: SalesComponent,
  },
  {
    path: 'results',
    component: ResultsComponent,
  },
  {
    path: 'result/:sale',
    component: ResultComponent,
  },
  {
    path: 'result/:sale/:item',
    component: ResultItemComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  /*{
    path: 'who',
    component: WhoComponent,
  },*/
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'cgu',
    component: CguComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'sale/:sale',
    component: SaleComponent,
  },
  {
    path: 'sale/:sale/:item',
    component: ItemComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard],
  },
  { path: '**', redirectTo: '' },
];

const config: ExtraOptions = {
  //useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
