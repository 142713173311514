import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CookiesManagementService } from '../../services/cookies.service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {

  constructor(
    private cookieService: CookieService,
    private cookiesManagementService: CookiesManagementService
  ) { }

  ngOnInit(): void {
  }

  public consentCookies(): void {
    this.cookieService.set('cc_consentCookie', 'true', 17280000);
    this.cookiesManagementService.setCookiesConsentment(true);
  }

}
