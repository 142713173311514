<div class="container">
    <div class="row">
        <div class="col-lg-6">
            <div class="sec-title sec-title-2">
                <h2>Politique de Confidentialité</h2>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-12 terms-content">
            <h3>
                ARTICLE 1– PROTECTION DES DONNEES PERSONNELLES
            </h3>
            <p>
                Lors de la création du compte, ou de l’inscription à la newsletter, l’utilisateur doit communiquer ses informations personnelles à l’éditeur afin que ce dernier puisse l’identifier. Ces informations conditionnent la création du compte ou la réception de la newsletter. A défaut, l’utilisateur ne pourra pas accéder  aux services conditionnés par l’inscription, mais pourra utiliser le site pour tous les services qui ne nécessitent pas l’obtention d’un compte.
            </p>
            <p>
                Conformément au règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 (relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données), et dans le respect des lois et règlements applicables, l’éditeur s’assure de la sécurité, ainsi que de la confidentialité des informations qui lui sont transmises par le biais de ce site. Notamment, un système de cryptage des mots de passe des utilisateurs est mis en place afin d’assurer au mieux la sécurité des données personnelles. L’éditeur n’aura donc pas accès à ces mots de passe.
            </p>
            <p>
                Pour que les données personnelles puissent être collectées et traitées, l’utilisateur doit avoir accepté la présente politique de confidentialité, ainsi que les Conditions Générales d’Utilisation. En ce qui concerne les mineurs et majeurs protégés, le fait d’accepter le traitement de ces données suppose une autorisation de la part du représentant légal.
            </p>
            <p>
                Les informations recueillies sur ce site sont enregistrées dans un fichier informatisé par la SARL ARDECHE ENCHERES, responsable du traitement des données. Le nom, le prénom, les coordonnées ainsi que les ventes enregistrées comme favorites sont des données personnelles collectées dans une finalité de gestion et connaissance des clients. Les alertes créées par l’utilisateur (donc ses coordonnées), ainsi que le Opt-in (fait d’accepter de recevoir la newsletter) et Opt-out (fait de refuser la newsletter) sont collectés à des fins de publicités concernant les ventes de la SARL ARDECHE ENCHERES. Ces données ont également pour finalité la fourniture des services aux utilisateurs, ainsi que le bon fonctionnement du site.
            </p>
            <p>
                La base légale du traitement est le consentement de l’utilisateur, qui est obligatoirement donné pour la création d’un compte sur le présent site.
            </p>
            <p>
                Les données collectées seront communiquées au seul destinataire suivant : la SARL ARDECHE ENCHERES. En aucun cas les données personnelles communiquées par les utilisateurs du site ne seront vendues ou louées à quelque partenaire que ce soit.
            </p>
            <p>
                Les données sont conservées pendant toute la durée de vie du compte de l’utilisateur et jusqu’à sa suppression par le titulaire du compte. 
            </p>
            <p>
                L’utilisateur peut accéder aux données le concernant, les rectifier, demander leur effacement ou exercer son droit à la limitation du traitement des données. L’utilisateur peut retirer à tout moment son consentement au traitement de ses données personnelles ou s’opposer à ce traitement. Pour ce faire, il doit supprimer le compte qu’il a créé sur le site. Cette action n’aura aucune incidence sur la licéité du traitement fondé sur le consentement effectué avant le retrait de celui-ci.
            </p>
            <p>
                Pour exercer ces droits ou pour toute question sur le traitement des données dans ce dispositif, l’utilisateur peut contacter l’éditeur à l’adresse communiquée aux termes l’article 1 des présentes CGU.
            </p>
            <p>
                Si l’utilisateur estime, après avoir contacté l’éditeur, que ses droits « Informatique et Libertés » ne sont pas respectés, il peut adresser une réclamation à la CNIL.
            </p>
            <p>
                Déclaration CNIL n° 1757313.
            </p>

            <h3>
                ARTICLE 2 – COOKIES
            </h3>
            <p>
                Dans le cadre d’une démarche d’amélioration du contenu, ce site collecte des informations (cookies). Ces cookies sont collectés à des fins de sécurité, d’authentification. L’objectif est d’assure le bon fonctionnement du site pour l’utilisateur. Pour ce faire, le site comprend l’utilisation de cookies. Les cookies sont des données installées directement sur le logiciel de navigation de l’utilisateur permettant de collecter les données relatives à la navigation, sans toutefois permettre à l’éditeur de l’identifier. Les cookies sont anonymisés et ne font en aucun cas l’objet d’un ciblage publicitaire.
            </p>
            <p>
                Les cookies sont réputés acceptés par l’utilisateur lorsqu’il poursuit sa navigation sur le site après avoir vu le message d’utilisation des cookies. Il est toutefois possible de les désactiver via le navigateur.
            </p>
        </div>
    </div>
</div>