import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookiesManagementService {
  
  private cookiesConsentmentInitialized: boolean = false;
  private cookiesConsentment: ReplaySubject<boolean> = new ReplaySubject();

  constructor(
    private cookieService: CookieService,
  ) { }

  public get cookiesConsentment$(): Observable<boolean> {
    if (!this.cookiesConsentmentInitialized) {
      const consentment = this.cookieService.get('cc_consentCookie') 
          && this.cookieService.get('cc_consentCookie') === 'true' 
        ? true 
        : false;
        this.cookiesConsentment.next(consentment);
    }
    return this.cookiesConsentment;
  }

  public setCookiesConsentment(value: boolean): void {
    this.cookiesConsentment.next(value);
  }

}
