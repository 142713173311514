import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import 'firebase/analytics';
import firebase from 'firebase/app';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public hide: boolean = true;
  public lastname: FormControl = new FormControl('', [Validators.required]);
  public firstname: FormControl = new FormControl('', [Validators.required]);
  public email: FormControl = new FormControl('', [Validators.required, Validators.email]);
  public password: FormControl = new FormControl('', [Validators.required]);
  public terms: FormControl = new FormControl('', [Validators.required]);
  public newsletter: FormControl = new FormControl('');

  public errorMessage: string;

  public submitted: boolean = false;

  constructor(
    private userService: UserService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit() {

    this.submitted = true;

    if (this.lastname.valid && this.firstname.valid && this.email.valid && this.password.valid && this.terms.valid) {
      const lastname = this.lastname.value;
      const firstname = this.firstname.value;
      const email = this.email.value;
      const password = this.password.value;
      const validated = false;
      const newsletter = this.newsletter.value;

      this.userService.createNewUser(email, password, lastname, firstname, validated, true).then(
        () => {
          firebase.analytics().logEvent('sign_up')
        },
        (error) => {
          this.errorMessage = error;
        }
      );
    }
  }

  public getLastNameErrorMessage() {
    if (this.lastname.hasError('required')) {
      return 'Veuillez saisir votre nom';
    }
  }

  public getFirstNameErrorMessage() {
    if (this.firstname.hasError('required')) {
      return 'Veuillez saisir votre prénom';
    }
  }

  public getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Veuillez saisir votre adresse e-mail';
    }

    return this.email.hasError('email') ? "L'e-mail est invalide" : "";
  }

  public getPasswordErrorMessage() {
    if (this.password.hasError('required')) {
      return 'Veuillez saisir votre mot de passe';
    }
  }

  public getTermsErrorMessage() {
    if (this.terms.hasError('required')) {
      return 'Vous devez accepter nos CGU et Politique de Confidentialité';
    }
  }

}
