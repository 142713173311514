import { Component, Inject, OnInit } from '@angular/core';
import { SalesService } from '../services/sales.service';
import * as models from '../services/models';
import { combineLatest, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { UserService } from '../services/user.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  picture: string;
}
@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

  constructor(
    private salesService: SalesService,
    private route: ActivatedRoute,
    public gallery: Gallery,
    public lightbox: Lightbox,
    private userService: UserService,
    public dialog: MatDialog,
  ) { }

  public loading: boolean;

  private sale: models.Sale;
  public item: models.Item;
  public rawItem: models.Item;
  public selectedPicture: string;
  public pictures: GalleryItem[];

  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    window.scroll(0, 0);
    this.loading = true;
    const lightboxRef = this.gallery.ref('lightbox');
    lightboxRef.setConfig({
      imageSize: ImageSize.Contain,
      thumbPosition: ThumbnailsPosition.Top
    });
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        if (params.sale && params.item) {
          this.subscriptions.push(
            this.salesService.getSale$(params.sale).subscribe((sale: models.Sale) => {
              if (sale) {
                this.sale = sale;
                const tempItem = sale.items.find(item => item.id === params.item)
                if (tempItem.pictures && typeof tempItem.pictures === 'string') {
                  tempItem.pictures = [tempItem.pictures]
                }
                this.rawItem = tempItem;
                this.subscriptions.push(
                  this.salesService.getItemPictures$(sale.id, this.rawItem).subscribe((item) => {
                    if (item.vehicule && item.vehicule.options && typeof item.vehicule.options === 'string') {
                      item.vehicule.options = [item.vehicule.options]
                    }
                    this.item = item;
                    if (this.item.pictures && typeof this.item.pictures === 'object' && this.item.pictures.length) {
                      this.pictures = this.item.pictures.map(p => new ImageItem({ src: p, thumb: p }));
                    } else if (this.item.pictures && typeof this.item.pictures === 'string') {
                      this.pictures = [new ImageItem({ src: this.item.pictures, thumb: this.item.pictures })];
                    }
                    lightboxRef.load(this.pictures);
                    this.loading = false;
                  }),
                );
              }
            }),
          );
        }
      }),
      combineLatest([
        this.userService.getUser$(),
        lightboxRef.itemClick,
      ]).subscribe(([user, pictureIndex]) => {
        if (user && user.admin) {
          const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
            width: '25vw',
            data: { picture: this.rawItem.pictures[pictureIndex] }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result && (result.includes('.jpg') || result.includes('.jpeg') || result.includes('.png')) && !result.includes('/')) {
              this.salesService.changeSalePicture(this.sale.id, result);
            }
          });
        }
      }),
    );
  }

  public selectPicture(newSelectedPicture: string): void {
    if (this.item.pictures && typeof this.item.pictures === 'object') {
      (this.item.pictures as string[]).push(this.selectedPicture);
      this.item.pictures = this.item.pictures.filter(p => p !== newSelectedPicture);
    } else if (this.item.pictures && typeof this.item.pictures === 'string') {
      this.item.pictures = this.selectedPicture;
    }
    this.selectedPicture = newSelectedPicture;
  }

}



@Component({
  selector: 'dialog-overview-example-dialog',
  template: '<h1 mat-dialog-title>Attention !</h1> \
      <div mat-dialog-content> \
      <p>Êtes-vous sûr de vouloir changer la photo principale de la vente ?</p> \
      <div mat-dialog-actions> \
        <button class="form-control" mat-button (click)="onNoClick()">Annuler</button> \
        <button class="form-control" mat-button (click)="onYesClick()">Oui</button> \
      </div> \
    </div>',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.dialogRef.close(this.data.picture);
  }

}

