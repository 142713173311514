import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import * as Leaflet from 'leaflet';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {

  public hide: boolean = true;
  public name: FormControl = new FormControl('', [Validators.required]);
  public email: FormControl = new FormControl('', [Validators.required, Validators.email]);
  public message: FormControl = new FormControl('', [Validators.required]);
  public recaptchaReactive: FormControl = new FormControl(null, [Validators.required])

  public errorMessage: string;

  public map: Leaflet.Map;

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.map = new Leaflet.Map('map').setView([45.239507, 4.64994], 13);
    Leaflet.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
    }).addTo(this.map);

    const icon = Leaflet.icon({
      iconUrl: '/assets/images/map-marker.png',
      iconSize: [48, 48],
    });

    Leaflet.marker([45.239507, 4.64994], { icon }).addTo(this.map);
  }

  onSubmit() {

    if (!this.recaptchaReactive.valid) {
      this.errorMessage = "Veuillez remplir le captcha"
    } else {
      if (this.name.valid && this.email.valid && this.message.valid) {
        const name = this.name.value;
        const email = this.email.value;
        const message = this.message.value;

        let data = {
          service_id: 'service_unxqnb9',
          template_id: 'template_irtxhn6',
          user_id: 'user_TwL8FFoiakOwcoBsX2XLF',
          template_params: {
            from_name: name,
            from_mail: email,
            message: message,
          }
        };

        this.http.post('https://api.emailjs.com/api/v1.0/email/send', data, { responseType: 'text' })
          .subscribe(() => {
            alert('Votre email a été envoyé !');
          }, (error: HttpErrorResponse) => {
            console.error(error.message);
          });
      }
    }
  }

  public getLastNameErrorMessage() {
    if (this.name.hasError('required')) {
      return 'Veuillez saisir votre nom';
    }
  }

  public getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Veuillez saisir votre adresse e-mail';
    }

    return this.email.hasError('email') ? "L'e-mail est invalide" : "";
  }

  public getMessageErrorMessage() {
    if (this.message.hasError('required')) {
      return 'Veuillez saisir votre message';
    }
  }

  ngOnDestroy(): void {
    this.map.remove();
  }

}
