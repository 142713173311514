<section class="single-bolg py-100-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 d-block d-lg-none d-xl-none">
                <div class="fixed-widgets">
                    <div class="widget">
                        <div class="widget-body">
                            <div class="search">
                                <input #search type="search" name="search" placeholder="Chercher un lot..." (keyup)="filterItems(search.value)">
                                <button class="click">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="widget">
                        <div class="widget-title">
                            <h3>Suivez-nous</h3>
                        </div>
                        <div class="widget-body">
                            <div class="follow">
                                <ul class="icon">
                                    <li><a href="https://www.facebook.com/gregory.helbourg" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                    <!-- <li><a href="#"><i class="fab fa-twitter"></i></a></li> -->
                                    <li><a href="https://www.instagram.com/ardecheencheres/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                </ul> 
                            </div>
                        </div>
                    </div>
                    <div class="widget">
                        <div class="widget-title">
                            <h3>Tags</h3>
                        </div>
                        <div class="widget-body" *ngIf="tags">
                            <div class="widget-body" *ngIf="tags">
                                <button mat-stroked-button *ngFor="let t of tags" (click)="toggleTagFilter(t.tag, t.id)" [class]="t.class"> 
                                    {{ t.tag }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="row">
                    <ng-template [ngIf]="!loading" [ngIfElse]="progressspinner">
                        <div class="col-md-12">
                            <div class="blog-item">
                                <div class="text-box" style="padding-top: 0px!important">
                                    <span class="blog-date"><fa-icon [icon]="faCalendarAlt" class="calendar-icon"></fa-icon>&nbsp; {{ sale.date | date: 'EEEE dd MMMM y' }} à {{ sale.date | date:'shortTime' }}</span> <br/>
                                    <h5>{{ sale.title }}</h5>
                                    <h4 *ngIf="sale.site"><span class="info-label">Lieu : </span>{{ sale.site }}</h4>
                                    <span *ngIf="sale.description"><span class="info-label">Description : </span>{{ sale.description }}</span> <br/>
                                    <span *ngIf="sale.access_info && sale.access_info !== '-'"><span class="info-label">Informations d'accès : </span>{{ sale.access_info }}</span> <br/>
                                    <span *ngIf="sale.exposition && sale.exposition !== '-'"><span class="info-label">Exposition : </span>{{ sale.exposition }}</span> <br/>
                                    <span *ngIf="sale.register_link && sale.register_link !== '-'"><span class="info-label">Inscription à la vente : </span><a [href]="sale.register_link" target="_blank">{{ sale.register_link }}</a></span> <br/>
                                    <span *ngIf="(sale.CGV_resume && sale.CGV_resume !== '-') || sale.CGV_link">
                                        <span class="info-label">Conditions Générales de vente : </span> <span *ngIf="sale.CGV_resume && sale.CGV_resume !== '-'">{{ sale.CGV_resume }} </span><br/>
                                        <a [href]="sale.CGV_link" target="_blank">Détail des CGV</a>
                                    </span>
    
                                    <h3>Lots en vente :</h3>
                                    <ng-container *ngFor="let item of sale.items">
                                        <div class="row sale-row py-1" *ngIf="item.matchFilter && item.auctionprice && item.auctionprice > 0" [routerLink]="['/result', sale.id, item.id]">
                                            <div class="col-md-5">
                                                <div class="open-post">
                                                    <img class="img-fluid" *ngIf="item.pictures && item.pictures.length" [src]="item.pictures[0]">
                                                </div>
                                            </div>
                                            <div class="col-md-7">
                                                <span class="item-number" *ngIf="item.number !== null">Lot n° {{ item.number }}</span> <br/>
                                                <span class="sale-name">{{ item.name | slice:0:85 }}</span>
                                                <p>Catégorie : {{ item.category }}</p>
                                                <span class="estimation">Estimation: {{ item.startprice && item.startprice > 0 ? item.startprice + ' €'  : '-' }}</span> <br/>
                                                <span class="estimation"><b>Prix d'adjudication:</b> {{ item.auctionprice && item.auctionprice > 0 ? item.auctionprice + ' €'  : '-' }}</span>
                                            </div>
                                            <div class="highlight-button-container" *ngIf="isAdmin">
                                                <ng-template [ngIf]="item.highlighted" [ngIfElse]="nothighlighted">
                                                    <button mat-icon-button (click)="highlightResult(sale.id, item.id, false)">
                                                        <mat-icon 
                                                            matTooltip="Retirer des résultats phares"
                                                            matTooltipPosition="left"
                                                            class="highlight-icon">
                                                            link
                                                        </mat-icon>
                                                    </button>
                                                </ng-template>
                                                <ng-template #nothighlighted>
                                                    <button mat-icon-button (click)="highlightResult(sale.id, item.id, true)">
                                                        <mat-icon 
                                                            matTooltip="Ajouter aux résultats phares" 
                                                            matTooltipPosition="left"
                                                            class="highlight-icon">
                                                            link_off
                                                        </mat-icon>
                                                    </button>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #progressspinner>
                        <div class="big-progress-spinner"></div>
                    </ng-template>
                </div>
            </div>
            <div class="col-lg-4 d-none d-lg-block d-xl-block">
                <div class="fixed-widgets">
                    <div class="widget">
                        <div class="widget-body">
                            <div class="search">
                                <input #search type="search" name="search" placeholder="Chercher un lot..." (keyup)="filterItems($event)">
                                <button class="click">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="widget">
                        <div class="widget-title">
                            <h3>Suivez-nous</h3>
                        </div>
                        <div class="widget-body">
                            <div class="follow">
                                <ul class="icon">
                                    <li><a href="https://www.facebook.com/gregory.helbourg" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                    <!-- <li><a href="#"><i class="fab fa-twitter"></i></a></li> -->
                                    <li><a href="https://www.instagram.com/ardecheencheres/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                </ul> 
                            </div>
                        </div>
                    </div>
                    <div class="widget">
                        <div class="widget-title">
                            <h3>Tags</h3>
                        </div>
                        <div class="widget-body" *ngIf="tags">
                            <button mat-stroked-button *ngFor="let t of tags" (click)="toggleTagFilter(t.tag, t.id)" [class]="t.class"> 
                                {{ t.tag }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>