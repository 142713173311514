import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { faHome, faUser, faGavel, faQuestion, faArchive, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogData } from 'src/app/admin/faqs/faqs.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private userService: UserService,
    public location: Location,
    public router: Router,
    public dialog: MatDialog,
  ) { }

  public activeLink: string;
  public faHome = faHome;
  public faUser = faUser;
  public faGavel = faGavel;
  public faArchive = faArchive;
  public faQuestion = faQuestion;

  public faTwitter = faTwitter;
  public faFacebook = faFacebook;
  public faInstagram = faInstagram;

  public navBarLinkClass = "nav-bar-link";

  ngOnInit(): void {
    this.activeLink = this.location.path() || '/';
  }

  public toggleMenuClass(event) {
    event.preventDefault();
    if (this.navBarLinkClass === "nav-bar-link") {
      this.navBarLinkClass = "nav-bar-link active";
    } else {
      this.navBarLinkClass = "nav-bar-link";
    }
  }

  public goToResults(event) {
    this.toggleMenuClass(event);
    this.userService.getUser$().subscribe(user => {
      if (user) {
        this.router.navigate(['/results']);
      } else {
        this.needLogin();
      }
    })
  }

  public needLogin(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '30vw'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/account']);
      }
    });
  }

}

@Component({
  selector: 'dialog-overview-example-dialog',
  template: '<h1 mat-dialog-title>Cette partie du site est réservée aux utilisateurs.</h1> \
      <div mat-dialog-content> \
      <p>Veuillez vous inscrire ou vous connecter pour continuer.</p> \
      <div class="container"> \
        <div mat-dialog-actions class="row"> \
          <button class="form-control col-md-5" mat-button (click)="onNoClick()">Annuler</button> \
          <button class="form-control col-md-5 offset-md-1" mat-button (click)="onYesClick()">Se connecter</button> \
        </div> \
      </div> \
    </div>',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

}
