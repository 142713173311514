<div class="container-fluid cookies-container">
    <div class="row justify-content-center">
        <div class="cookies-div col-lg-11 py-3">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <span>
                        En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de cookies strictement nécessaires au bon fonctionnement du site. 
                        Pour plus d'informations, veuillez consulter notre <a routerLink="/terms">Politique de Confidentialité</a>
                    </span>
                </div>
                <div class="col-lg-1">
                    <button mat-stroked-button (click)="consentCookies()" class="options-buttons"> 
                        <mat-icon>done</mat-icon> OK
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>