<ng-container *ngIf="!this.router.url.includes('/admin')">
    <header class="navs">         
        <!-- :: Navbar -->
        <nav class="nav-bar">
            <div class="container">
                <div class="box-content d-flex align-items-center justify-content-between">
                    <div class="logo">
                        <a href="index.html" class="logo-nav">
                            <img class="img-fluid one" src="/assets/images/logo-white.png" alt="01 Logo">
                            <img class="img-fluid two" src="/assets/images/logo-blue.png" alt="02 Logo">
                        </a>
                        <a (click)="toggleMenuClass($event)" class="open-nav-bar">
                            <span></span>
                            <span></span>
                            <span></span>
                        </a>
                    </div>
                    <div [class]="navBarLinkClass">
                        <ul class="level-1">
                            <li><a [class]="this.location.path() === '/' || this.location.path() === '' ? 'color-active' : ''" routerLink="/" (click)="toggleMenuClass($event)">Accueil</a></li>
                            <li><a [class]="this.location.path().includes('sale') ? 'color-active' : ''" routerLink="/sales" (click)="toggleMenuClass($event)">Ventes</a></li>
                            <li><a [class]="this.location.path().includes('result') ? 'color-active' : ''" (click)="goToResults($event)">Résultats</a></li>
                            <li><a [class]="this.location.path().includes('faq') ? 'color-active' : ''" routerLink="/faq" (click)="toggleMenuClass($event)">FAQ</a></li>
                            <!-- <li><a [class]="this.location.path().includes('who') ? 'color-active' : ''" routerLink="/who" (click)="toggleMenuClass($event)">Qui sommes-nous ?</a></li> -->
                            <li><a [class]="this.location.path().includes('account') ? 'color-active' : ''" routerLink="/account" (click)="toggleMenuClass($event)">Mon compte</a></li>
                        </ul>
                    </div>
                    <div class="info-nav">
                        <i class="flaticon-location"></i>
                        <div class="contact-nav">
                            <p>Hotel des ventes à Annonay</p>
                            <span>Avenue de Stalingrad</span>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>
    
    <section class="breadcrumb-header" id="page" style="background-image: url(assets/images/judge_hammer_header.jpg)">
        <div class="overlay"></div>
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="banner">
                        <ul>
                            <li><a routerLink="/">Accueil</a></li>
                            <li><i class="fas fa-angle-right"></i></li>
                            <li *ngIf="this.location.path().includes('sale')">Ventes</li>
                            <li *ngIf="this.location.path().includes('result')">Résultats</li>
                            <li *ngIf="this.location.path().includes('faq')">FAQ</li>
                            <!-- <li *ngIf="this.location.path().includes('who')">Qui sommes-nous ?</li> -->
                            <li *ngIf="this.location.path().includes('account')">Mon compte</li>
                            <li *ngIf="this.location.path().includes('contact')">Contact</li>
                            <li *ngIf="this.location.path().includes('cgu')">Conditions Générales d'Utilisation</li>
                            <li *ngIf="this.location.path().includes('terms')">Politique de confidentialité</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-container>