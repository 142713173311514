<div class="sec-title sec-title-2">
    <h2 class="title">Je m'inscris</h2>
    <div class="row justify-content-center register-fields-container">
        <div class='col-lg-6 p-0'>
            <div class="col-md-10 float-right">
                <mat-form-field class="w-100">
                    <input matInput placeholder="Nom" type="text" [formControl]="lastname" required>
                </mat-form-field>
                <span *ngIf="lastname.invalid && lastname.touched" class="error-message">{{ getLastNameErrorMessage() }}</span>
            </div>
            <div class="col-md-10 float-right">
                <mat-form-field class="w-100">
                    <input matInput placeholder="Prénom" type="text" [formControl]="firstname" required>
                </mat-form-field>
                <span *ngIf="firstname.invalid && firstname.touched" class="error-message">{{ getFirstNameErrorMessage() }}</span>
            </div>
        </div>
        <div class='col-lg-6 p-0'>
            <div class="col-md-10">
                <mat-form-field class="w-100">
                    <input matInput placeholder="Entrez votre e-mail" type="email" [formControl]="email" required>
                </mat-form-field>
                <span *ngIf="email.invalid && email.touched" class="error-message">{{ getEmailErrorMessage() }}</span>
            </div>
            <div class="col-md-10">
                <mat-form-field class="w-100">
                    <input matInput placeholder="Entrez votre mot de passe" [type]="hide ? 'password' : 'text'" [formControl]="password" required>
                    <mat-icon matSuffix (click)="hide = !hide" class="hide-password-button">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <span *ngIf="password.invalid && password.touched" class="error-message">{{ getPasswordErrorMessage() }}</span>
            </div>
        </div>
        <div class='col-lg-10 p-0'>
            <div class="col-md-10">
                <input class="col-md-1 p-0 pl-0" type="checkbox" id="terms" name="terms" [formControl]="terms" required>
                <label class="col-md-11 p-0" for="terms">&nbsp;&nbsp; En m'inscrivant, je confirme avoir lu et accepter les <a routerLink="/cgu">CGU</a> et <a routerLink="/terms">Politique de Confidentialité</a></label>
                <span *ngIf="terms.invalid && submitted" class="error-message mt-3">{{ getTermsErrorMessage() }}</span>
            </div>
            <div class="col-md-10">
                <input class="col-md-1 p-0 pl-0" type="checkbox" id="newsletter" name="newsletter" [formControl]="newsletter">
                <label class="col-md-11 p-0" for="newsletter">&nbsp;&nbsp; J'accepte de recevoir la newsletter.</label>
            </div>
        </div>
        <div class='col-lg-10 mt-3'>
            <div class="quote-item">
                <a class="btn-1" (click)="onSubmit()">S'inscrire</a>
                <span *ngIf="errorMessage" class="error-message"> {{ errorMessage }} </span>
            </div>
        </div>
    </div>
</div>
