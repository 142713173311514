import { Component, Inject, OnInit } from '@angular/core';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { SalesService } from '../services/sales.service';
import * as models from '../services/models';
import { combineLatest, Subscription } from 'rxjs';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../services/user.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/admin/faqs/faqs.component';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {

  constructor(
    private userService: UserService,
    private salesService: SalesService,
    public dialog: MatDialog,
    public router: Router,
  ) { }

  public loading: boolean;

  public faCalendarAlt = faCalendarAlt;
  public faStar = farStar;

  public sales: models.Sale[];

  public incomingSales: models.Sale[] = [];

  private subscriptions: Subscription[] = [];

  public logged: boolean = false;
  public user: models.User;
  public nextSaleDate: Date = null;

  ngOnInit(): void {
    window.scroll(0, 0);
    this.loading = true;
    this.subscriptions.push(
      combineLatest([
        this.salesService.getSales$(),
        this.userService.getUser$(),
        this.salesService.getNextSale(),
      ]).subscribe(([sales, user, nextSaleDate]) => {
        if (nextSaleDate) {
          this.nextSaleDate = new Date(nextSaleDate);
        }

        this.sales = sales.sort((a, b) => {
          if (a.date < b.date)
            return -1;
          if (a.date > b.date)
            return 1;
          return 0;
        });


        const now = new Date().getTime() - (24 * 60 * 60 * 1000);

        this.incomingSales = this.sales.filter(sale => {
          const saleDate = new Date(sale.date).getTime();
          return now <= saleDate
        });

        this.incomingSales.map(sale => {
          let fileName: string;
          if (sale.default_picture) {
            fileName = sale.default_picture;
          } else {
            if (typeof sale.items[0]?.pictures === 'string' && sale.items[0]?.pictures) {
              fileName = sale.items[0]?.pictures;
            } else if (typeof sale.items[0]?.pictures === 'object' && sale.items[0]?.pictures) {
              fileName = sale.items[0]?.pictures[0];
            }
          }
          this.salesService.getItemPicture$(sale.id, fileName).subscribe((url) => {
            this.incomingSales = this.incomingSales.map(newSale => {
              if (newSale.id === sale.id) {
                newSale.picture = url;
              }
              return newSale
            })
          })
        })

        if (user) {
          this.user = user;
          this.logged = true;
          this.incomingSales = this.incomingSales.map(sale => ({
            ...sale,
            favorited: this.user.fav_sales && this.user.fav_sales.includes(sale.id),
          }))
        } else {
          this.user = null;
          this.logged = false;
          this.incomingSales = this.incomingSales.map(sale => ({
            ...sale,
            favorited: false,
          }))
        }

        this.loading = false;
      }),
    );
  }

  public favSale(saleId: string) {
    this.userService.getUser$().pipe(first()).subscribe(user => {
      if (user) {
        this.userService.addFavorite(this.user, saleId).subscribe(() => {
          this.incomingSales = this.incomingSales.map(sale => {
            if (sale.id === saleId) {
              return {
                ...sale,
                favorited: true,
              }
            } else {
              return sale
            }
          });
        })
      } else {
        this.needLogin();
      }
    })
  }

  public unfavSale(saleId: string) {
    this.userService.getUser$().pipe(first()).subscribe(user => {
      if (user) {
        this.userService.deleteFavorite(this.user, saleId).subscribe(() => {
          this.incomingSales = this.incomingSales.map(sale => {
            if (sale.id === saleId) {
              return {
                ...sale,
                favorited: false,
              }
            } else {
              return sale
            }
          });
        })
      } else {
        this.needLogin();
      }
    })
  }

  public needLogin(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '30vw'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/account']);
      }
    });
  }

}


@Component({
  selector: 'dialog-overview-example-dialog',
  template: '<h1 mat-dialog-title>Cette fonctionnalité est réservée aux utilisateurs.</h1> \
      <div mat-dialog-content> \
      <p>Veuillez vous inscrire ou vous connecter pour continuer.</p> \
      <div class="container"> \
        <div mat-dialog-actions class="row"> \
          <button class="form-control col-md-5" mat-button (click)="onNoClick()">Annuler</button> \
          <button class="form-control col-md-5 offset-md-1" mat-button (click)="onYesClick()">Se connecter</button> \
        </div> \
      </div> \
    </div>',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

}
