<div class="container">
    <div class="row">
        <div class="col-lg-6">
            <div class="sec-title sec-title-2">
                <h2>Contact</h2>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-12 contact-content">
            <h3>
                Informations
            </h3>
            <p>
                Maître Grégory HELBOURG <br/>
                Quartier Rochebrune <br/>
                Route du Puy <br/>
                07100 Annonay <br/>
                04.75.33.68.52 <br/>
            </p>

            <div id="map" class="col-md-10" style="height: 270px"></div>

            <h3>
                Formulaire de contact
            </h3>
            <div class="col-md-10 p-0">
                <div class="quote-item">
                    <input type="text" placeholder="Nom" [formControl]="name" required>
                    <span *ngIf="name.invalid && name.touched" class="error-message">{{ getLastNameErrorMessage() }}</span>
                </div>
            </div>
            <div class="col-lg-10 p-0">
                <div class="quote-item">
                    <input placeholder="E-mail" [formControl]="email" required>
                    <span *ngIf="email.invalid && email.touched" class="error-message">{{ getEmailErrorMessage() }}</span>
                </div>
            </div>
            <div class="col-lg-10 p-0">
                <div class="quote-item">
                    <textarea placeholder="Votre message" [formControl]="message" required></textarea>
                    <span *ngIf="message.invalid && message.touched" class="error-message message-error">{{ getMessageErrorMessage() }}</span>
                </div>
            </div>
            <div class="col-lg-10 p-0 mb-5">
                <re-captcha [formControl]="recaptchaReactive"></re-captcha>
                <span *ngIf="errorMessage" class="error-message captcha-error"> {{ errorMessage }} </span>
            </div>
            <div class="col-lg-10 p-0">
                <div class="quote-item">
                    <a class="btn-1" (click)="onSubmit()">Envoyer</a>                
                </div>
            </div>
        </div>
    </div>
</div>