
<section class="single-bolg pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <ng-template [ngIf]="!loading" [ngIfElse]="progressspinner">
                        <div class="col-md-4 picture-container" *ngIf="item.pictures">
                            <div [ngStyle]="{'background-image': 'url('+item.pictures[0]+')'}" class="default-picture">
                                <button mat-raised-button (click)="lightbox.open(0)" *ngIf="pictures" class="picture-button">Voir les photos</button>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="blog-item">
                                <div class="text-box">
                                    <h5 style="color: #C89D66!important; margin-bottom: -20px!important;" *ngIf="item.number !== null">Lot n° {{ item.number }}</h5>
                                    <h5>{{ item.name }}</h5>
                                    <span><span class="item-info-label">Catégorie : </span>{{ item.category }}</span> <br/>
                                    <span><span class="item-info-label">Estimation : </span>{{ item.startprice && item.startprice > 0 ? item.startprice + ' €'  : '-' }}</span> <br/>
                                    <span *ngIf="item.vehicule" class="item-info-label">Informations sur le véhicule : </span>
                                    <ul *ngIf="item.vehicule" class="item-info-list">
                                        <li *ngIf="item.vehicule.brand"><span class="item-info-label">Marque : </span>{{ item.vehicule.brand }}</li>
                                        <li *ngIf="item.vehicule.model"><span class="item-info-label">Modèle : </span>{{ item.vehicule.model }}</li>
                                        <li *ngIf="item.vehicule.color"><span class="item-info-label">Couleur : </span>{{ item.vehicule.color }}</li>
                                        <li *ngIf="item.vehicule.registrationdate"><span class="item-info-label">1ère M.E.C : </span>{{ item.vehicule.registrationdate }}</li>
                                        <li *ngIf="item.vehicule.kilometer"><span class="item-info-label">Kilométrage compteur : </span>{{ item.vehicule.kilometer }} km</li>
                                        <li *ngIf="item.vehicule.body"><span class="item-info-label">Carroserie : </span>{{ item.vehicule.body }}</li>
                                        <li *ngIf="item.vehicule.energy"><span class="item-info-label">Energie : </span>{{ item.vehicule.energy }}</li>
                                        <li *ngIf="item.vehicule.observations"><span class="item-info-label">Observations : </span>{{ item.vehicule.observations }}</li>
                                    </ul>
                                    <span *ngIf="item.vehicule && item.vehicule.options" class="item-info-label">Options : </span>
                                    <ul *ngIf="item.vehicule && item.vehicule.options" class="item-info-list">
                                        <li *ngFor="let option of item.vehicule.options">{{ option }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #progressspinner>
                        <div class="big-progress-spinner"></div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</section>