<ng-container *ngIf="!this.router.url.includes('/admin')">
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="logo">
                        <img class="img-fluid" src="assets/images/logo-white.png" alt="Footer Logo">
                        <p>Hotel des ventes d'Annonay</p>
                        <ul>
                            <li><a href="https://www.facebook.com/gregory.helbourg" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <!-- <li><a href="#"><i class="fab fa-twitter"></i></a></li> -->
                            <li><a href="https://www.instagram.com/ardecheencheres/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 offset-lg-1">
                    <div class="footer-title">
                        <h4>Pages</h4>
                    </div>
                    <ul class="links">
                        <li><a routerLink="/">Accueil</a></li>
                        <li><a routerLink="/sales">Ventes</a></li>
                        <li><a routerLink="/results">Résultats</a></li>
                        <li><a routerLink="/faq">FAQ</a></li>
                        <li><a routerLink="/account">Mon compte</a></li>
                    </ul>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <div class="newsletter">
                        <h5>Newsletter</h5>
                        <p>Inscrivez-vous pour recevoir notre newsletter</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="container">
                <p>© 2021 Ardèche Enchères. Par <a href="https://anthonyeveraert.com/">Everaert Anthony</a></p>
                <ul>
                    <li><a routerLink="/cgu">CGU</a></li>
                    <li><a routerLink="/terms">Politique de confidentialité</a></li>
                    <li><a routerLink="/contact">Contact</a></li>
                </ul>
            </div>
        </div>
    </footer>
</ng-container>