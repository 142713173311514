<div class="container py-100-70">
    <div class="row">
        <div class="col-lg-6">
            <div class="sec-title sec-title-2" *ngIf="results">
                <h2>Résultats des ventes ({{results.length}})</h2>
            </div>
        </div>
    </div>
</div>

<ng-template [ngIf]="!loading" [ngIfElse]="secondprogressspinner">
    <ng-template [ngIf]="results && results.length > 0" [ngIfElse]="nopastsale">
        <section class="about py-4" *ngFor="let sale of results">
            <div class="container">
                <div class="row sale-row py-3">
                    <div class="col-lg-5">
                        <div class="img-box">
                            <div class="sale-img" [style]="{'background-image': 'url(' + sale.picture + ')'}">
                            </div>
                            <div class="case-about">
                                <div class="counter">{{ sale.items.length }}</div>
                                <h5>Lots</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 offset-lg-1">
                        <div class="text-box">
                            <div class="sec-title">
                                <fa-icon [icon]="faCalendarAlt" class="calendar-icon"></fa-icon> <h2>{{ sale.date | date: 'EEEE dd MMMM y' }} à {{ sale.date | date:'shortTime' }}</h2>
                                <h3 class="sale-title">{{ sale.title }}</h3>
                                <h5 class="sale-adress"><i class="flaticon-location sale-location-icon"></i> {{ sale.site }}</h5>
                            </div>
                            <div class="row see-more-container">
                                <div class="col-sm-6">
                                    <a class="btn-1" [routerLink]="['/result', sale.id]">Voir les résultats</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </ng-template>
    <ng-template #nopastsale>
        <div class="container no-sale-container">
            <div class="row">
                <div class="col-lg-6">
                    <span>Aucun résultat pour le moment.</span>
                </div>
            </div>
        </div>
    </ng-template>
</ng-template>
<ng-template #secondprogressspinner>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 spinner-container">
                <div class="lil-progress-spinner"></div>
            </div>
        </div>
    </div>
</ng-template>
