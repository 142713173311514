import { Component, OnInit } from '@angular/core';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { SalesService } from '../services/sales.service';
import * as models from '../services/models';
import { combineLatest, Subscription } from 'rxjs';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../services/user.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private userService: UserService,
    private salesService: SalesService
  ) { }

  public loading: boolean = true;
  public faCalendarAlt = faCalendarAlt;
  public faStar = farStar;
  public sales: models.Sale[];
  public incomingSales: models.Sale[] = [];
  public nextSale: models.Sale;
  private subscriptions: Subscription[] = [];
  public logged: boolean = false;
  public user: models.User;
  public highlights: models.Sale[];
  public highlightsloading: boolean = true;
  public isAdmin: boolean = false;
  public nextSaleDate: Date = null;
  public currentDate: Date = new Date();

  ngOnInit(): void {
    window.scroll(0, 0);
    this.subscriptions.push(
      this.userService.getUser$().subscribe(user => this.isAdmin = user && user.admin),
      combineLatest([
        this.salesService.getSales$(),
        this.userService.getUser$(),
        this.salesService.getHighlights$(),
        this.salesService.getNextSale(),
      ]).subscribe(([sales, user, highlights, nextSaleDate]) => {
        if (nextSaleDate) {
          this.nextSaleDate = new Date(nextSaleDate);
        }
        this.highlights = highlights;
        this.highlightsloading = false;
        this.sales = sales.sort((a, b) => {
          if (a.date < b.date)
            return -1;
          if (a.date > b.date)
            return 1;
          return 0;
        });

        const now = new Date().getTime() - (24 * 60 * 60 * 1000);

        this.incomingSales = this.sales.filter(sale => {
          const saleDate = new Date(sale.date).getTime();
          return now <= saleDate;
        });

        this.incomingSales.map(sale => {
          let fileName: string;
          if (sale.default_picture) {
            fileName = sale.default_picture;
          } else {
            if (typeof sale.items[0]?.pictures === 'string' && sale.items[0]?.pictures) {
              fileName = sale.items[0]?.pictures;
            } else if (typeof sale.items[0]?.pictures === 'object' && sale.items[0]?.pictures) {
              fileName = sale.items[0]?.pictures[0];
            }
          }
          this.salesService.getItemPicture$(sale.id, fileName).subscribe((url) => {
            this.incomingSales = this.incomingSales.map(newSale => {
              if (newSale.id === sale.id) {
                newSale.picture = url;
              }
              return newSale
            })
          })
        })

        if (user) {
          this.user = user;
          this.logged = true;
          this.incomingSales = this.incomingSales.map(sale => ({
            ...sale,
            favorited: this.user.fav_sales && this.user.fav_sales.includes(sale.id),
          }))
        } else {
          this.user = null;
          this.logged = false;
          this.incomingSales = this.incomingSales.map(sale => ({
            ...sale,
            favorited: false,
          }))
        }
        const higlightedNextSale = this.incomingSales.find(sale => sale.highlighted);
        this.nextSale = higlightedNextSale ? higlightedNextSale : this.incomingSales[0];
        this.loading = false;
      }),
    );
  }

  public highlightResult($event: Event, saleId: string, itemId: string, highlightRes: boolean) {
    $event.stopPropagation(); // Only seems to
    $event.preventDefault();
    this.highlights = this.highlights.map(highlight => ({
      ...highlight,
      items: highlight.items.map(i => ({
        ...i,
        highlighted: i.id === itemId
          ? highlightRes
          : i.highlighted || false
      }))
    }))
    this.salesService.highlightResultItem(saleId, itemId, highlightRes);
  }

}
