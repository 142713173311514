import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import 'firebase/analytics';
import firebase from 'firebase/app';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Input() relog: boolean = false;
  @Input() forcedEmail: string = "";
  @Output() private relogged: EventEmitter<boolean> = new EventEmitter();
  @Output() private cancelRelogEvent: EventEmitter<boolean> = new EventEmitter();

  public hide = true;
  public email = new FormControl('', [Validators.required, Validators.email]);
  public password = new FormControl('', [Validators.required]);
  public errorMessage: string;

  constructor(
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (this.forcedEmail) {
      this.email.setValue(this.forcedEmail)
    }
  }

  onSubmit() {
    this.email.markAsTouched();
    this.password.markAsTouched();

    if (this.email.valid && this.password.valid) {
      const email = this.email.value;
      const password = this.password.value;

      if (this.relog) {
        this.userService.relog(email, password).then(
          () => {
            this.relogged.emit(true);
          },
          (error) => {
            this.errorMessage = error;
          }
        );
      }
      this.userService.signInUser(email, password).then(
        () => {
          firebase.analytics().logEvent('login')
        },
        (error) => {
          this.errorMessage = error;
        }
      );
    }
  }

  public getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Veuillez saisir votre adresse e-mail';
    }

    return this.email.hasError('email') ? "L'e-mail est invalide" : "";
  }

  public getPasswordErrorMessage() {
    if (this.password.hasError('required')) {
      return 'Veuillez saisir votre mot de passe';
    }
  }

  public goToPasswordReset(event) {
    event.stopPropagation();
    this.router.navigate(['/reset-password'], { queryParams: { email: this.email.value } });
  }

}
