import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { Observable } from 'rxjs';
import * as models from './models';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
  ) { }

  private db = firebase.database();
  private user: models.User;
  private users: models.User[];
  private apiKey: string = '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';

  public getUser$(): Observable<models.User> {
    return new Observable((observer) => {
      firebase.auth().onAuthStateChanged((user: firebase.UserInfo) => {
        if (user) {
          this.db.ref('users/' + user.uid).once('value').then((snapshot) => {
            if (snapshot.exists()) {
              if (!this.user) {
                this.user = snapshot.val();
              }
              observer.next(this.user);
            } else {
              observer.next(null);
            }
          });
        } else {
          observer.next(null);
        }
      })
    })
  }

  public getUsers$(refresh?: boolean): Observable<models.User[]> {
    return new Observable((observer) => {
      this.db.ref('users/').once('value').then((snapshot) => {
        if (snapshot.exists()) {
          if (!this.users || refresh) {
            const usersMap = snapshot.val();
            this.users = [];
            Object.keys(usersMap).map(userId => this.users.push(usersMap[userId]));
          }
          observer.next(this.users);
        } else {
          observer.next(null);
        }
      });
    })
  }

  public createNewUser(email: string, password: string, lastname: string, firstname: string, validated: boolean, newsletter: boolean) {
    return new Promise(
      (resolve, reject) => {
        this.getUserNewsletterChoice(email).subscribe(() => {
          firebase.auth().createUserWithEmailAndPassword(email, password).then(
            (userCredential) => {
              const id = userCredential.user.uid;
              const date = new Date();
              this.db.ref('users/' + id).set({
                id,
                email,
                lastname,
                firstname,
                validated,
                created_on: date.toString(),
                admin: false,
                newsletter,
                allow_alerts: false,
              }).then(() => {
                this.updateUserNewsletterChoice(email, newsletter);
              });
              resolve(userCredential);
            },
            (error) => {
              reject(error);
            }
          );
        }, (error) => {
          if (error && error.error && error.error.code === "document_not_found") {
            firebase.auth().createUserWithEmailAndPassword(email, password).then(
              (userCredential) => {
                const id = userCredential.user.uid;
                const date = new Date();
                this.db.ref('users/' + id).set({
                  id,
                  email,
                  lastname,
                  firstname,
                  validated,
                  created_on: date.toString(),
                  admin: false,
                  newsletter,
                  allow_alerts: false,
                }).then(() => {
                  this.createUserNewsletterChoice(email, newsletter);
                });
                resolve(userCredential);
              },
              (error) => {
                reject(error);
              }
            );
          } else {
            reject(error);
          }
        })
      }
    );
  }

  public signInUser(email: string, password: string) {
    return new Promise(
      (resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(email, password).then(
          (userCredential) => {
            resolve(userCredential);
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  public signOutUser() {
    firebase.auth().signOut();
    this.user = null;
  }


  public addFavorite(user: models.User, saleId: string): Observable<any> {
    return new Observable((observer) => {
      const newFavs = user.fav_sales ? [...user.fav_sales, saleId] : [saleId];
      this.db.ref('users/' + user.id + '/fav_sales/').set(newFavs)
        .then(() => {
          this.user.fav_sales = newFavs;
          observer.next(true);
          observer.complete();
        });
    });
  }

  public deleteFavorite(user: models.User, saleId: string): Observable<any> {
    return new Observable((observer) => {
      const newFavs = user.fav_sales.filter(sale => sale !== saleId);
      this.db.ref('users/' + user.id + '/fav_sales/').set(newFavs)
        .then(() => {
          this.user.fav_sales = newFavs;
          observer.next(true);
          observer.complete();
        });
    });
  }

  public addAlert(user: models.User, alert: string): Observable<any> {
    return new Observable((observer) => {
      const newAlerts = user.alerts ? [...user.alerts] : [];
      if (!newAlerts.includes(alert)) {
        newAlerts.push(alert);
        this.db.ref('users/' + user.id + '/alerts/').set(newAlerts)
          .then(() => {
            this.user.alerts = newAlerts;
            observer.next(true);
            observer.complete();
          });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  public deleteAlert(user: models.User, alert: string): Observable<any> {
    return new Observable((observer) => {
      const newAlerts = user.alerts.filter(a => a !== alert);
      this.db.ref('users/' + user.id + '/alerts/').set(newAlerts)
        .then(() => {
          this.user.alerts = newAlerts;
          observer.next(true);
          observer.complete();
        });
    });
  }

  public sendPasswordResetEmail(email: string): Observable<any> {
    return new Observable((observer) => {
      firebase.auth().sendPasswordResetEmail(email).then(() => {
        observer.next(true);
        observer.complete();
      }).catch((error) => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public updatePassword(newPassword: string): Observable<any> {
    return new Observable((observer) => {
      firebase.auth().currentUser.updatePassword(newPassword).then(() => {
        observer.next(true);
        observer.complete();
      }).catch((error) => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public relog(email: string, password: string) {
    return new Promise(
      (resolve, reject) => {
        const credential = firebase.auth.EmailAuthProvider.credential(
          email,
          password
        );
        firebase.auth().currentUser.reauthenticateWithCredential(credential).then(
          (userCredential) => {
            resolve(userCredential);
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  public updateUserAdmin(userId: number, admin: boolean): Observable<any> {
    return new Observable((observer) => {
      this.db.ref('users/' + userId + '/admin/').set(admin)
        .then(() => {
          observer.next(true);
          observer.complete();
        });
    });
  }

  public verifyUser(userId: number, verified: boolean): Observable<any> {
    return new Observable((observer) => {
      this.db.ref('users/' + userId + '/validated/').set(verified)
        .then(() => {
          observer.next(true);
          observer.complete();
        });
    });
  }

  public deleteUser() {
    const user = firebase.auth().currentUser;
    user.delete().then(() => {
      this.db.ref('users/' + user.uid).remove().then(() => { })
    }).catch((error) => { console.error(error) });
  }

  public getUserNewsletterChoice(email: string): Observable<boolean> {
    return this.http.get(
      'https://api.sendinblue.com/v3/contacts/' + email,
      {
        headers: {
          Accept: 'application/json',
          'api-key': this.apiKey,
        }
      }
    ).pipe(
      map((contact) => !contact['emailBlacklisted'])
    )
  }

  public updateUserNewsletterChoice(email: string, choice: boolean): void {
    this.http.put(
      'https://api.sendinblue.com/v3/contacts/' + email,
      {
        emailBlacklisted: !choice,
        listIds: [16]
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'api-key': this.apiKey,
        }
      }
    ).subscribe()
  }

  public createUserNewsletterChoice(email: string, choice: boolean): void {
    this.http.post(
      'https://api.sendinblue.com/v3/contacts',
      {
        email,
        emailBlacklisted: !choice,
        listIds: [16]
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'api-key': this.apiKey,
        }
      }
    ).subscribe();
  }

  public updateUserAlertsChoice(id: string, choice: boolean): void {
    this.db.ref('users/' + id + '/allow_alerts').set(choice).then(() => { });
  }

}
